import React, { useState, useContext, useEffect } from 'react'
import { useCurrentUser } from '../../../currentUserContext'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import SaveIcon from '@material-ui/icons/Save'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import IconButton from '@material-ui/core/IconButton'
import FilledInput from '@material-ui/core/FilledInput'
import InputAdornment from '@material-ui/core/InputAdornment'

import { Button } from '@material-ui/core'
import Spinner from '../../../UI/Spinner'
import SystemInfo from '../../../UI/SystemInfo'
import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import { useParams } from 'react-router-dom'

import '../../../../asset/style.css'

const USER = loader('./graphql/user.graphql')
const UPDATE_USER = loader('./graphql/updateUser.graphql')
const ROLES = loader('../../create/CreateUser/graphql/allRoles.graphql')

const EditUser = () => {
	const { updateName } = useContext(Context)
	const params = useParams()
	const currentUser = useCurrentUser()
	const [values, setValues] = useState({
		showPassword: false,
	})

	const { data: { user = null } = {}, loading: userLoading } = useQuery(USER, {
		variables: { id: params.id },
	})
	const { data: { allRoles = null } = {}, loading: rolesLoading } =
		useQuery(ROLES)
	const [updateUser, { data, error, loading }] = useMutation(UPDATE_USER)

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value })
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		const { role, name, username, password, color } = e.target
		return updateUser({
			variables: {
				input: {
					id: params.id,
					name: name.value,
					userName: username.value,
					password: password ? password.value : null,
					roleId: role.value,
					color: color.value,
				},
			},
		})
	}

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword })
	}

	const handleMouseDownPassword = (event) => {
		event.preventDefault()
	}

	useEffect(() => {
		updateName(
			JSON.stringify([
				{
					level: '1',
					title: 'Modifier utilisateur',
					url: `/modifier-utilisateur/${user && user?.id}`,
				},
				{
					level: '2',
					title: `${user && user?.userName}`,
					url: `/modifier-utilisateur/${user && user?.id}`,
				},
			])
		)
	}, [updateName, user])

	useDocumentTitle(
		user ? "Modifer l'utilisateur " + user?.userName : 'Modifer utilisateur'
	)

	if (!currentUser) return <Redirect to="/" />

	if (userLoading || loading || rolesLoading) return <Spinner />

	if (data) {
		localStorage.setItem(
			'userUpdateMsg',
			"✔️ L'utilisateur a été modifié avec succès ! "
		)
		return <Redirect to="/liste-utilisateurs" />
	}

	return (
		<>
			{user && (
				<Box
					className="mainZone"
					component="form"
					noValidate
					autoComplete="off"
					onSubmit={handleSubmit}
				>
					<FormGroup className="formGrp" row>
						<TextField
							defaultValue={user.name}
							label="Name"
							name="name"
							size="small"
							margin="normal"
							helperText="Le nom qui sera inséré dans ce champ servira à créer la signature de l'e-mail."
						/>
						<TextField
							defaultValue={user.userName}
							label="Username"
							name="username"
							size="small"
							margin="normal"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								),
							}}
						/>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<FormControl className="formSelect" size="small" margin="normal">
							<InputLabel>Rôle</InputLabel>
							{allRoles && (
								<Select
									labelId="rôle"
									name="role"
									defaultValue={user?.roles[0]?.id}
								>
									{allRoles.map(({ id, role }) => {
										if (role === 'accountant') {
											return (
												<MenuItem key={id} value={id}>
													Gestionaire
												</MenuItem>
											)
										} else if (role === 'admin') {
											return (
												<MenuItem key={id} value={id}>
													Administrateur
												</MenuItem>
											)
										} else if (role === 'employee') {
											return (
												<MenuItem key={id} value={id}>
													Employé
												</MenuItem>
											)
										}
									})}
								</Select>
							)}
						</FormControl>
						<FormControl variant="filled">
							<InputLabel>Password</InputLabel>
							<FilledInput
								name="password"
								label="password"
								size="small"
								margin="normal"
								type={values.showPassword ? 'text' : 'password'}
								value={values.password}
								onChange={handleChange('password')}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge="end"
										>
											{values.showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
						<TextField
							label="Couleur"
							name="color"
							type="color"
							size="small"
							margin="normal"
							defaultValue={user.color}
						/>
					</FormGroup>
					<FormGroup className="formGrp formSubmitBtn" row>
						<Button
							className="submitBtn"
							type="submit"
							variant="contained"
							color="primary"
						>
							{' '}
							<SaveIcon />
							&nbsp;ENREGISTRER{' '}
						</Button>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<SystemInfo
							createdBy={user.createdBy}
							createdAt={user.createdAt}
							updatedBy={user.updatedBy}
							updatedAt={user.updatedAt}
						/>
					</FormGroup>
				</Box>
			)}
		</>
	)
}

export default EditUser
