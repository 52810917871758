import React, { useContext, useEffect } from 'react'
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	frFR,
} from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery, useLazyQuery } from '@apollo/client'
import Spinner from '../../../UI/Spinner'
import Stack from '@mui/material/Stack'

import CallOut from '@mui/icons-material/CallMissedOutgoing'
import CallIn from '@mui/icons-material/CallMissed'
import CallIcon from '@mui/icons-material/Call'

import { Context } from '../../../breadcrumsContext'
import { useCurrentUser } from '../../../currentUserContext'
import { useDocumentTitle } from '../../../documentTitle'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import '../../../../asset/style.css'

import EditIcon from '@material-ui/icons/BorderColor'

const ALL_CALLS = loader('./graphql/allCalls.graphql')

export default function CallList() {
	const { updateName } = useContext(Context)
	const currentUser = useCurrentUser()
	useDocumentTitle('Liste des calls')

	const isAdmin =
		currentUser && currentUser.roles.find(({ role }) => role === 'admin')

	const {
		data: { allCalls = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_CALLS)

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
			</GridToolbarContainer>
		)
	}

	const columns = [
		{
			field: 'actions',
			headerName: 'Action',
			filterable: false,
			sortable: false,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<Link
						to={`/modifier-call/${thisRow.id}`}
						style={{ margin: '0 auto' }}
						className="listEditBtn"
					>
						<EditIcon className="editBtnIcon" /> Modifier
					</Link>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 150,
			hide: true,
			flex: 1,
			filterable: false,
			sortable: false,
		},
		{
			field: 'client',
			headerName: 'Client',
			minWidth: 200,
			flex: 1,

			renderCell: (params) => {
				const { row } = params
				return (
					<strong style={{ textTransform: 'capitalize', marginLeft: '20px' }}>
						{row.client.company}
					</strong>
				)
			},
		},
		{
			field: 'task',
			headerName: 'Tache',
			minWidth: 200,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return row.task.number
			},
		},
		{
			field: 'callType',
			headerName: 'Type',
			minWidth: 200,
			flex: 1,
			renderCell: (params) => {
				const { row } = params

				return row.callType === 'in' ? (
					<span
						style={{
							display: 'flex',
							alignItems: 'center',
							marginLeft: '20px',
						}}
					>
						Entrant &nbsp;&nbsp;
						<CallIcon /> <CallIn />
					</span>
				) : (
					<span
						style={{
							display: 'flex',
							alignItems: 'center',
							marginLeft: '20px',
						}}
					>
						Sortant &nbsp;&nbsp;
						<CallIcon /> <CallOut />
					</span>
				)
			},
		},
		{
			field: 'comment',
			headerName: 'Commentaire',
			minWidth: 200,
			flex: 1,
		},
	]

	if (localStorage.getItem('callUpdateMsg') !== '') {
		toast.success(localStorage.getItem('callUpdateMsg'))
	}

	if (localStorage.getItem('callCreateMsg') !== '') {
		toast.success(localStorage.getItem('callCreateMsg'))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('callUpdateMsg', '')
			localStorage.setItem('callCreateMsg', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [allCalls])

	useEffect(() => {
		updateName(
			JSON.stringify([
				{ level: '1', title: 'Liste des calls', url: '/liste-calls' },
			])
		)
	}, [updateName])

	if (loading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-center" transition={Slide} />
			{allCalls && (
				<DataGrid
					disableSelectionOnClick={true}
					localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
					rows={allCalls}
					autoHeight={true}
					columns={
						isAdmin
							? columns
							: columns.filter(
									(col) => col.field !== 'email' && col.field !== 'phone'
							  )
					}
					components={{
						Toolbar: CustomToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}
		</>
	)
}
