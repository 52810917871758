import React, { useState, useEffect } from 'react'
import { useCurrentUser } from '../../currentUserContext'
import { Redirect, Link } from 'react-router-dom'
import clsx from 'clsx'

// import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer'
import Tooltip from '@material-ui/core/Tooltip'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PeopleAlt from '@material-ui/icons/PeopleAlt'
import IconButton from '@material-ui/core/IconButton'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import NoteAddIcon from '@mui/icons-material/NoteAdd'

import AddTaskIcon from '@mui/icons-material/AddTask'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd'
import BookmarksIcon from '@mui/icons-material/Bookmarks'
import BarChartIcon from '@mui/icons-material/BarChart'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount'
import DashboardIcon from '@mui/icons-material/Dashboard'
import MyTasks from '@mui/icons-material/AssignmentInd'
import TagIcon from '@mui/icons-material/LocalOffer'
import CallIcon from '@mui/icons-material/PhoneForwarded'
import AddCallIcon from '@mui/icons-material/AddIcCall'

import TextSnippetIcon from '@mui/icons-material/TextSnippet'

import Logout from '../../interaction/Logout'
import BreadcrumbsCompennent from '../breadcrumbs'

import { useTheme } from '@material-ui/core/styles'

import useStyles from './useStyles'

const Header = () => {
	const currentUser = useCurrentUser()
	const [open, setOpen] = useState(false)

	const theme = useTheme()
	const isAdmin =
		currentUser && currentUser.roles.find(({ role }) => role === 'admin')

	const isEmployee =
		currentUser && currentUser.roles.find(({ role }) => role === 'employee')

	const isAccountant =
		currentUser && currentUser.roles.find(({ role }) => role === 'accountant')

	const {
		logo,
		appBar,
		appBarShift,
		menuButton,
		hide,
		icon,
		drawer,
		drawerOpen,
		drawerClose,
		toolbar,
		headerUserInfo,
	} = useStyles()

	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const handleDrawerClose = () => {
		setOpen(false)
	}

	if (!currentUser) return <Redirect to="/" />

	return (
		<>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={clsx(appBar, {
					[appBarShift]: open,
				})}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="Ouvrire le menu"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(menuButton, {
							[hide]: open,
						})}
					>
						<MenuIcon />
					</IconButton>
					<Link to="/home" style={{ textDecoration: 'none' }}>
						{/* <img src={Logo} alt="Home" className={logo} /> */}
					</Link>
					<Typography
						className="mainHeaderTitle"
						variant="h5"
						noWrap
						style={{ color: '#fff', textDecoration: 'none !important' }}
					>
						Paycore Task Logger
					</Typography>
					<BreadcrumbsCompennent />
					<Logout
						style={{
							marginRight: '0px',
							marginLeft: 'auto',
							cursor: 'pointer',
						}}
					/>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				className={clsx(drawer, {
					[drawerOpen]: open,
					[drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[drawerOpen]: open,
						[drawerClose]: !open,
					}),
				}}
			>
				<div className={toolbar}>
					<IconButton onClick={handleDrawerClose} style={{ float: 'right' }}>
						{theme.direction === 'rtl' ? (
							<ChevronRightIcon />
						) : (
							<ChevronLeftIcon />
						)}
					</IconButton>
				</div>
				<br />
				<Divider />

				<List>
					<ListItem button key="dashboard" component={Link} to={'/dashboard/'}>
						<ListItemIcon className={icon}>
							<Tooltip placement="right" title="Dashboard">
								<DashboardIcon style={{ color: '#0F2765' }} />
							</Tooltip>
						</ListItemIcon>
						<ListItemText primary="Dashboard" />
					</ListItem>
					<ListItem button key="mesTaches" component={Link} to={'/mes-taches/'}>
						<ListItemIcon className={icon}>
							<Tooltip placement="right" title="Mes tâches">
								<MyTasks style={{ color: '#0F2765' }} />
							</Tooltip>
						</ListItemIcon>
						<ListItemText primary="Mes tâches" />
					</ListItem>
					<Divider />
					<ListItem
						button
						key="nouvelleTache"
						component={Link}
						to={'/nouvelle-tache/'}
					>
						<ListItemIcon className={icon}>
							<Tooltip placement="right" title="Nouvelle tâche">
								<AddTaskIcon style={{ color: '#F07E62' }} />
							</Tooltip>
						</ListItemIcon>
						<ListItemText primary="Nouvelle tâche" />
					</ListItem>
					<ListItem
						button
						key="listeTaches"
						component={Link}
						to={'/liste-taches/'}
					>
						<ListItemIcon className={icon}>
							<Tooltip placement="right" title="Liste des tâches">
								<TaskAltIcon style={{ color: '#F07E62' }} />
							</Tooltip>
						</ListItemIcon>
						<ListItemText primary="Liste des tâches" />
					</ListItem>
				</List>

				<Divider />
				<List>
					<ListItem
						button
						key="nouveauCall"
						component={Link}
						to={'/nouveau-call/'}
					>
						<ListItemIcon className={icon}>
							<Tooltip placement="right" title="Nouveau appels">
								<AddCallIcon style={{ color: '#6D454C' }} />
							</Tooltip>
						</ListItemIcon>
						<ListItemText primary="Nouveau appels" />
					</ListItem>
					<ListItem button key="listCall" component={Link} to={'/liste-calls/'}>
						<ListItemIcon className={icon}>
							<Tooltip placement="right" title="Liste des appels">
								<CallIcon style={{ color: '#6D454C' }} />
							</Tooltip>
						</ListItemIcon>
						<ListItemText primary="Liste des appels" />
					</ListItem>
				</List>
				<Divider />
				<List>
					<ListItem
						button
						key="nouveauClient"
						component={Link}
						to={'/nouveau-client/'}
					>
						<ListItemIcon className={icon}>
							<Tooltip placement="right" title="Nouveau client">
								<GroupAddIcon style={{ color: '#4ACFF0' }} />
							</Tooltip>
						</ListItemIcon>
						<ListItemText primary="Nouveau client" />
					</ListItem>
					<ListItem
						button
						key="listeClients"
						component={Link}
						to={'/liste-clients/'}
					>
						<ListItemIcon className={icon}>
							<Tooltip placement="right" title="Liste des clients">
								<PeopleAlt style={{ color: '#4ACFF0' }} />
							</Tooltip>
						</ListItemIcon>
						<ListItemText primary="Liste des clients" />
					</ListItem>
					<Divider />

					{isEmployee ? null : (
						<>
							<ListItem
								button
								key="nouveauService"
								component={Link}
								to={'/nouveau-service/'}
							>
								<ListItemIcon className={icon}>
									<Tooltip placement="right" title="Nouveau service">
										<BookmarkAddIcon style={{ color: '#F0B932' }} />
									</Tooltip>
								</ListItemIcon>
								<ListItemText primary="Nouveau service" />
							</ListItem>
							<ListItem
								button
								key="listeServices"
								component={Link}
								to={'/liste-services/'}
							>
								<ListItemIcon className={icon}>
									<Tooltip placement="right" title="List des services">
										<BookmarksIcon style={{ color: '#F0B932' }} />
									</Tooltip>
								</ListItemIcon>
								<ListItemText primary="Liste des services" />
							</ListItem>
							<ListItem
								button
								key="listeClientsTarifs"
								component={Link}
								to={'/liste-clients-tarifs/'}
							>
								<ListItemIcon className={icon}>
									<Tooltip placement="right" title="Tarifs par client">
										<SwitchAccountIcon style={{ color: '#F0B932' }} />
									</Tooltip>
								</ListItemIcon>
								<ListItemText primary="Tarifs par client" />
							</ListItem>

							<Divider />
						</>
					)}
				</List>

				{isEmployee ? null : (
					<List>
						<ListItem
							button
							key="listeClientAvecActiveTaches"
							component={Link}
							to={'/liste-client-avec-active-taches/'}
						>
							<ListItemIcon className={icon}>
								<Tooltip placement="right" title="Nouvelle facture">
									<NoteAddIcon style={{ color: '#3EF0B3' }} />
								</Tooltip>
							</ListItemIcon>
							<ListItemText primary="Nouvelle facture" />
						</ListItem>
						<ListItem button key="factures" component={Link} to={'/factures/'}>
							<ListItemIcon className={icon}>
								<Tooltip placement="right" title="Factures">
									<HomeRepairServiceIcon style={{ color: '#3EF0B3' }} />
								</Tooltip>
							</ListItemIcon>
							<ListItemText primary="Factures" />
						</ListItem>
						<Divider />
						{isAdmin && (
							<>
								{' '}
								<ListItem
									button
									key="rapports"
									component={Link}
									to={'/rapports/'}
								>
									<ListItemIcon className={icon}>
										<Tooltip placement="right" title="Rapports">
											<BarChartIcon style={{ color: '#388FD9' }} />
										</Tooltip>
									</ListItemIcon>
									<ListItemText primary="Rapports" />
								</ListItem>
								<ListItem
									button
									key="listeUtilisateurs"
									component={Link}
									to={'/liste-utilisateurs/'}
								>
									<ListItemIcon className={icon}>
										<Tooltip placement="right" title="Liste des utilisateurs">
											<ManageAccountsIcon style={{ color: '#388FD9' }} />
										</Tooltip>
									</ListItemIcon>
									<ListItemText primary="Liste des utilisateurs" />
								</ListItem>
								<ListItem
									button
									key="listeTags"
									component={Link}
									to={'/liste-tags/'}
								>
									<ListItemIcon className={icon}>
										<Tooltip placement="right" title="Liste des tags">
											<TagIcon style={{ color: '#388FD9' }} />
										</Tooltip>
									</ListItemIcon>
									<ListItemText primary="Liste des tags" />
								</ListItem>
								<ListItem
									button
									key="listeTemplates"
									component={Link}
									to={'/liste-templates/'}
								>
									<ListItemIcon className={icon}>
										<Tooltip placement="right" title="Liste des models">
											<TextSnippetIcon style={{ color: '#388FD9' }} />
										</Tooltip>
									</ListItemIcon>
									<ListItemText primary="Liste des models" />
								</ListItem>
							</>
						)}
					</List>
				)}
			</Drawer>
		</>
	)
}

export default Header
