import React, { useEffect, useContext } from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { StylesProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'

import { Context } from '../../breadcrumsContext'
import { useDocumentTitle } from '../../documentTitle'

const useStyles = makeStyles({
	root: {
		width: '100vw',
		height: '70vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		padding: '20px',
		width: '70vw',
	},
	link: {
		textDecoration: 'none',
	},
})

const Page404 = (props) => {
	const { updateName } = useContext(Context)
	useDocumentTitle('Page introuvable 404')

	const classes = useStyles(props)

	useEffect(() => {
		updateName(
			JSON.stringify([
				{ level: '1', title: 'Page introuvable 404', url: '/home' },
			])
		)
	}, [updateName])

	return (
		<>
			<StylesProvider>
				<div className={classes.root}>
					<Paper className={classes.paper}>
						<h1>Page not found :(</h1>
						<p>
							Maybe the page you are looking for has been removed, or you typed
							a wrong URL
						</p>
						<div>
							<Button
								color="primary"
								variant="contained"
								component={Link}
								to="/home"
							>
								Retour
							</Button>
						</div>
					</Paper>
				</div>
			</StylesProvider>
		</>
	)
}

export default Page404
