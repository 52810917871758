import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { Redirect } from 'react-router-dom'
//import Spinner from '../../UI/Spinner'
import { loader } from 'graphql.macro'
import { useCurrentUser } from '../../currentUserContext'

//Material UI
import Avatar from '@material-ui/core/Avatar'
// import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LockIcon from '@mui/icons-material/Lock'

import InputAdornment from '@mui/material/InputAdornment'

import Typography from '@material-ui/core/Typography'

import LoadingBar from '../../UI/Spinner'
import Logo from '../../../asset/logo.png'
import { useDocumentTitle } from '../../documentTitle'
import './style.css'

import Cookies from 'js-cookie'

function Copyright() {
	return (
		<Typography variant="body2" align="center" className="loginCopyright">
			{'Copyright © '}
			<Link href="https://htag.be/" target="_blank">
				Htag IT SOLUTIONS
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	)
}

const LOGIN = loader('./graphql/login.graphql')

const Login = () => {
	useDocumentTitle('Page de connexion')
	const currentUser = useCurrentUser()

	const [values, setValues] = useState({
		userName: '',
		password: '',
	})
	const [login, { data, error, loading }] = useMutation(LOGIN)

	const handleSubmit = (e) => {
		e.preventDefault()
		return login({
			variables: {
				input: values,
			},
		})
	}

	useEffect(() => {
		if (data) {
			if (data.login) {
				Cookies.set('token', data.login.token, { sameSite: 'lax' })
				window.location.reload()
			}
		}
	}, [data])

	if (currentUser) return <Redirect to="/dashboard" />

	return (
		<>
			{data && data.login ? (
				<div>
					<Redirect to="/dashboard" />
				</div>
			) : loading ? (
				//<Spinner />
				<LoadingBar />
			) : (
				<Grid container component="main" id="logingBody">
					<CssBaseline />

					<div className="loginSection">
						<img src={Logo} className="loginLogo" />
						<h1>Login</h1>
						<form onSubmit={handleSubmit}>
							<TextField
								variant="standard"
								margin="normal"
								required
								id="email"
								placeholder="Username"
								autoComplete="email"
								name="userName"
								onChange={({ target: { value } }) => {
									setValues({ ...values, userName: value })
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<AccountCircleIcon style={{ color: '#FF9100' }} />
										</InputAdornment>
									),
								}}
							/>
							<TextField
								variant="standard"
								margin="normal"
								required
								placeholder="Password"
								type="password"
								id="password"
								autoComplete="current-password"
								name="password"
								onChange={({ target: { value } }) => {
									setValues({ ...values, password: value })
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<LockIcon style={{ color: '#FF9100' }} />
										</InputAdornment>
									),
								}}
							/>
							{error &&
								error.graphQLErrors.length &&
								error.graphQLErrors[0].message && (
									<p>{error.graphQLErrors[0].message}</p>
								)}
							-{' '}
							<Button
								type="submit"
								variant="contained"
								margin="normal"
								color="primary"
							>
								Sign In
							</Button>
							<Copyright />
						</form>
					</div>
				</Grid>
			)}
		</>
	)
}

export default Login
