import React, { useEffect, useState, useRef, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useParams } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { useCurrentUser } from '../../../currentUserContext'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import SaveIcon from '@material-ui/icons/Save'
import { Button } from '@material-ui/core'

import Drawer from '@mui/material/Drawer'
import BaseTextareaAutosize from '@mui/base/TextareaAutosize'
import AttachmentIcon from '@mui/icons-material/Attachment'
import SendIcon from '@mui/icons-material/Send'
import Fab from '@mui/material/Fab'
import Avatar from '@mui/material/Avatar'

import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import ChatIcon from '@mui/icons-material/Chat'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'

import Tooltip from '@mui/material/Tooltip'

import Autocomplete from '@mui/material/Autocomplete'

import Spinner from '../../../UI/Spinner'
import SystemInfo from '../../../UI/SystemInfo'
import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

// const ListItem = styled('li')(({ theme }) => ({
// 	margin: theme.spacing(0.5),
// }))

const Textarea = styled(BaseTextareaAutosize)(
	() => `
    width: 100%;
	max-with:100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: #24292f;
    background: #fff;
    border: 1px solid #d0d7de;
    box-shadow: 0px 2px 2px #f6f8fa;
	resize: none;

    &:hover {
      border-color:#3399FF;
    }

    &:focus {
      border-color: #3399FF;
      box-shadow: 0 0 0 3px #b6daff;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
)

const ALL_CLIENTS = loader('./graphql/allClients.graphql')
const ALL_SERVICES = loader('./graphql/allServices.graphql')
const USERS = loader('./graphql/users.graphql')
const TASK = loader('./graphql/task.graphql')
const UPDATE_TASK = loader('./graphql/updateTask.graphql')
const ADD_SERVICE_TO_TASK = loader('./graphql/addServiceToTask.graphql')
const REMOVE_SERVICE_FROM_TASK = loader(
	'./graphql/removeServiceFromTask.graphql'
)

const ALL_TAGS = loader('./graphql/allTags.graphql')
const ADD_TAG_TO_TASK = loader('./graphql/addTagToTask.graphql')
const REMOVE_TAG_FROM_TASK = loader('./graphql/removeTagFromTask.graphql')

const EditTask = () => {
	const { updateName } = useContext(Context)
	const params = useParams()
	const currentUser = useCurrentUser()
	const [clientId, setClientId] = useState(null)
	const [assignedTo, setAssignedTo] = useState()
	const [chosenStatus, setChosenStatus] = useState('inProgress')
	const [serviceId, setServiceId] = useState(null)
	const [drawerState, setDrawerState] = useState(false)
	const [selectedTag, setSelectedTag] = useState(null)

	const autoCTag = useRef(null)
	const autoCService = useRef(null)

	const isAdmin =
		currentUser && currentUser.roles.find(({ role }) => role === 'admin')

	const { data: { allTags = null } = {}, loading: loadingTags } =
		useQuery(ALL_TAGS)

	const { data: { allClients = null } = {}, loading: clientsLoading } =
		useQuery(ALL_CLIENTS)
	const { data: { allServices = null } = {}, loading: serviceLoading } =
		useQuery(ALL_SERVICES)
	const { data: { users = null } = {}, loading: usersLoading } = useQuery(USERS)

	const {
		data: { task = null } = {},
		loading: taskLoading,
		refetch,
	} = useQuery(TASK, {
		variables: { id: params.id },
		fetchPolicy: 'cache-and-network',
	})

	const [
		updateTask,
		{
			data: taskUpdateData,
			error: taskUpdateerror,
			loading: taskUpdateloading,
		},
	] = useMutation(UPDATE_TASK, { refetchQueries: task })

	const [
		addServiceToTask,
		{
			data: serviceToTaskData,
			error: serviceToTask,
			loading: serviceToTaskloading,
		},
	] = useMutation(ADD_SERVICE_TO_TASK)

	const [
		removeServiceFromTask,
		{
			data: removeServiceFromTaskData,
			error: removeServiceFromTaskError,
			loading: removeServiceFromTaskloading,
		},
	] = useMutation(REMOVE_SERVICE_FROM_TASK)

	const [
		updateReassignTask,
		{
			data: taskReassignData,
			error: taskReassignError,
			loading: taskReassignLoading,
		},
	] = useMutation(UPDATE_TASK)

	const [
		addTagToTask,
		{
			data: addTagToTaskData,
			error: addTagToTaskError,
			loading: addTagToTaskloading,
		},
	] = useMutation(ADD_TAG_TO_TASK)

	const [
		removeTagFromTask,
		{
			data: removeTagFromTaskData,
			error: removeTagFromTaskError,
			loading: removeTagFromTaskloading,
		},
	] = useMutation(REMOVE_TAG_FROM_TASK)

	const handleAddService = (e) => {
		e.preventDefault()
		const { quantity, remark } = e.target
		let theRemark = remark.value
		let theQuantity = quantity.value

		//clear the fields
		const serviceClear = autoCService.current.getElementsByClassName(
			'MuiAutocomplete-clearIndicator'
		)[0]
		serviceClear.click()
		remark.value = ''
		quantity.value = 1

		return addServiceToTask({
			variables: {
				input: {
					id: params.id,
					serviceId,
					quantity: Number(theQuantity),
					remark: theRemark,
				},
			},
		})
	}

	const handelAddTagToTask = (tagId) => {
		setSelectedTag(tagId)
		// Add the tag to the task
		return addTagToTask({
			variables: {
				input: {
					id: params.id,
					tagId,
				},
			},
			refetchQueries: [
				{
					query: TASK,
					variables: { id: params.id },
				},
			],
		}).then(() => {
			// Reset the selected tag after it's added to the task
			setSelectedTag(null)
		})
	}

	const handleDeleteTag = (tagId) => {
		removeTagFromTask({
			variables: {
				input: {
					id: params.id,
					tagId,
				},
			},
			refetchQueries: [
				{
					query: TASK,
					variables: { id: params.id },
				},
			],
		})
	}

	const handelReassignTicket = () => {
		return updateReassignTask({
			variables: {
				input: {
					id: params.id,
					assignedTo: currentUser.id,
				},
			},
		}).then(refetch)
	}

	const handelRemoveServiceFromTask = ({ serviceId }) => {
		return removeServiceFromTask({
			variables: {
				input: {
					id: params.id,
					serviceId,
				},
			},
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		return updateTask({
			variables: {
				input: {
					id: params.id,
					clientId: clientId || task.client.id,
					assignedTo,
					status: chosenStatus,
				},
			},
		})
	}

	const toggleDrawer = (open, event) => {
		console.log(open)
		setDrawerState(open)
	}

	useEffect(() => {
		refetch()
	}, [
		task,
		serviceToTaskData,
		taskUpdateData,
		taskReassignData,
		removeServiceFromTaskData,
	])

	useEffect(() => {
		updateName(
			JSON.stringify([
				{
					level: '1',
					title: 'Modifier tâche',
					url: `/modifier-tache/${task && task?.id}`,
				},
				{
					level: '2',
					title: `${task && task?.number}`,
					url: `/modifier-tache/${task && task?.id}`,
				},
			])
		)
	}, [updateName, task])

	useDocumentTitle(task ? 'Modifer la tâche ' + task?.number : 'Modifer tâche')

	if (!currentUser) return <Redirect to="/" />

	if (
		clientsLoading ||
		serviceLoading ||
		taskLoading ||
		usersLoading ||
		taskUpdateloading ||
		taskReassignLoading ||
		serviceToTaskloading ||
		removeServiceFromTaskloading ||
		loadingTags
	)
		return <Spinner />

	return (
		<>
			{removeServiceFromTaskError && alert(removeServiceFromTaskError?.message)}
			{task && (
				<>
					<Box
						sx={{ '& > :not(style)': { m: 1 } }}
						style={{ position: 'fixed', right: '20px', top: '130px' }}
					>
						<Fab color="primary" aria-label="add">
							<ChatIcon onClick={() => toggleDrawer(true)} />
						</Fab>
					</Box>
					<Box className="mainZone">
						<FormGroup className="formGrp" row>
							<Paper
								sx={{
									display: 'flex',
									justifyContent: 'flex-start',
									flexWrap: 'no-wrap',
									alignItems: 'center',
									width: '80%',
									height: '50px',
									paddingLeft: '20px',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
										marginRight: '10px',
									}}
								>
									<LocalOfferIcon />
									<Autocomplete
										disabled={task.status === 'invoiced'}
										ref={autoCTag}
										onChange={(i, value) => {
											handelAddTagToTask(value?.id)
										}}
										options={allTags.filter(
											(tag) => !task.tags.some((t) => t.id === tag.id)
										)}
										getOptionLabel={(option) => option.title}
										renderOption={(props, option) => (
											<Box
												key={option.id}
												component="li"
												sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
												{...props}
											>
												<Chip
													color={option.color}
													label={option.title}
													style={{
														m: 1,
														width: '150px',
														textAlign: 'center',
													}}
												/>
											</Box>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label="Tags"
												name="tag"
												size="small"
												margin="dense"
											/>
										)}
									/>
								</Box>
								<ul
									className="tagList"
									style={{
										paddingTop: '15px',
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center', // Center horizontally
										width: '90%',
										flexWrap: 'no-wrap',
										listStyle: 'none',
										overflowX: 'scroll',
										overflowY: 'hidden', // Hide the vertical scrollbar
										paddingLeft: 0,
									}}
								>
									{task?.tags &&
										task.tags.map((tag, i) => (
											<li value={tag.id} key={i}>
												<Chip
													color={tag.color}
													label={tag.title}
													onDelete={() => handleDeleteTag(tag.id)}
												/>
											</li>
										))}
								</ul>
							</Paper>
							<Button
								disabled={currentUser.id === task?.assignedTo?.id}
								variant="contained"
								color="inherit"
								onClick={handelReassignTicket}
								sx={{ width: '5%' }}
							>
								S'assigner cette tâche
							</Button>
						</FormGroup>
						<FormGroup className="formGrp" row>
							<TextField
								defaultValue={task.number}
								label="Numéro"
								size="small"
								margin="normal"
								disabled
							/>
							{allClients && (
								<Autocomplete
									disabled={!isAdmin ? true : false}
									onChange={(event, value) => setClientId(value?.id)}
									defaultValue={allClients.find(
										({ id }) => id === task.client.id
									)}
									options={allClients}
									getOptionLabel={(option) => option.company}
									renderOption={(props, option) => (
										<Box
											key={option.id}
											component="li"
											sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
											{...props}
										>
											&nbsp;&nbsp;{option.company}
										</Box>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Client"
											name="client"
											size="small"
											required
											margin="normal"
										/>
									)}
								/>
							)}

							{users && (
								<FormControl
									className="formSelect"
									size="small"
									margin="normal"
								>
									<InputLabel>Assigné à</InputLabel>
									<Select
										label="Assigné à"
										name="assignedTo"
										disabled={
											task?.assignedTo?.id !== currentUser.id && !isAdmin
												? true
												: false
										}
										defaultValue={task?.assignedTo?.id}
										onChange={({ target }) => setAssignedTo(target.value)}
									>
										{users.map(({ id, userName }) => (
											<MenuItem key={id} value={id}>
												{userName}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
							<FormControl className="formSelect" size="small" margin="normal">
								<InputLabel>Status</InputLabel>
								<Select
									disabled={
										(task?.assignedTo?.id !== currentUser.id && !isAdmin
											? true
											: false) || task.status === 'invoiced'
									}
									labelId="Status"
									name="Status"
									defaultValue={task?.status}
									onChange={({ target }) => setChosenStatus(target.value)}
								>
									<MenuItem key="inProgress" value="inProgress">
										En cours
									</MenuItem>
									<MenuItem key="pending" value="pending">
										En attente
									</MenuItem>
									<MenuItem key="invoiced" value="invoiced">
										Terminer
									</MenuItem>
								</Select>
							</FormControl>
						</FormGroup>
						<Paper
							style={{
								width: '100%',
								height: '100px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								padding: ' 0 20px',
								marginBottom: '20px',
							}}
						>
							<Box
								component="form"
								noValidate
								autoComplete="off"
								onSubmit={handleAddService}
								style={{
									width: '100%',
									height: '100px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									padding: ' 0 20px',
									marginBottom: '20px',
								}}
							>
								<FormGroup className="formGrp" row>
									<Autocomplete
										disabled={
											(task?.assignedTo?.id !== currentUser.id && !isAdmin
												? true
												: false) || task.status === 'invoiced'
										}
										ref={autoCService}
										onChange={(i, value) => {
											setServiceId(value?.id)
										}}
										options={
											task.servicesInTask.length
												? allServices.filter(
														({ id }) =>
															!task.servicesInTask.find(
																({ service }) => service.id === id
															)
												  )
												: allServices
										}
										style={{ marginRight: '20px' }}
										getOptionLabel={(option) => option.name}
										renderOption={(props, option) => (
											<Box
												key={option.id}
												component="li"
												sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
												{...props}
											>
												&nbsp;&nbsp;{option.name}
											</Box>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Services"
												name="service"
												size="small"
												margin="dense"
											/>
										)}
									/>
									<TextField
										disabled={
											(task?.assignedTo?.id !== currentUser.id && !isAdmin
												? true
												: false) || task.status === 'invoiced'
										}
										label="Quantité"
										name="quantity"
										defaultValue={1}
										size="small"
										type="number"
										margin="dense"
										inputProps={{ min: 0 }}
									/>
									<TextField
										disabled={
											(task?.assignedTo?.id !== currentUser.id && !isAdmin
												? true
												: false) || task.status === 'invoiced'
										}
										label="Remarque"
										name="remark"
										size="small"
										margin="dense"
										style={{ marginRight: '20px', width: '500px' }}
									/>
									<Button
										type="submit"
										variant="contained"
										color="primary"
										disabled={
											(serviceId ? false : true) || task.status === 'invoiced'
										}
									>
										+ Ajouter
									</Button>
								</FormGroup>
							</Box>
						</Paper>
						<Paper
							sx={{
								width: '100%',
								maxHeight: '300px',
								overflowY: 'scroll',
								mb: 2,
							}}
						>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>
											Services
										</TableCell>
										<TableCell align="right" style={{ fontWeight: 'bold' }}>
											Quantity
										</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>
											Remarque
										</TableCell>
										<TableCell align="right"></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{task.servicesInTask &&
										task.servicesInTask.map((elm, i) => {
											return (
												<TableRow key={i}>
													<TableCell component="th" scope="row">
														{elm?.service.name}
													</TableCell>
													<TableCell align="right">{elm?.quantity}</TableCell>
													<TableCell>{elm?.remark}</TableCell>
													<TableCell align="right">
														{task.status === 'invoiced' ? (
															''
														) : (
															<Tooltip title="Retiré">
																<IconButton
																	disabled={
																		task?.assignedTo?.id !== currentUser.id &&
																		!isAdmin
																			? true
																			: false
																	}
																	edge="end"
																	aria-label="delete"
																	onClick={() =>
																		handelRemoveServiceFromTask({
																			serviceId: elm?.service?.id,
																		})
																	}
																>
																	<DeleteIcon />
																</IconButton>
															</Tooltip>
														)}
													</TableCell>
												</TableRow>
											)
										})}
								</TableBody>
							</Table>{' '}
						</Paper>
						<FormGroup className="formGrp formSubmitBtn" row>
							<Button
								className="submitBtn"
								type="submit"
								variant="contained"
								color="primary"
								onClick={handleSubmit}
								disabled={
									(task?.assignedTo?.id !== currentUser.id && !isAdmin
										? true
										: false) || task.status === 'invoiced'
								}
							>
								{' '}
								<SaveIcon />
								&nbsp;Enregistrer{' '}
							</Button>
						</FormGroup>
						<FormGroup className="formGrp" row>
							<SystemInfo
								createdBy={task.createdBy}
								createdAt={task.createdAt}
								updatedBy={task.updatedBy}
								updatedAt={task.updatedAt}
							/>
						</FormGroup>
					</Box>
					<Drawer
						anchor="right"
						open={drawerState}
						onClose={() => toggleDrawer(false)}
						PaperProps={{
							style: {
								display: 'flex',
								width: '700px',
								height: '100%',
								paddingTop: '70px',
								flexDirection: 'column',
								alignContent: 'center',
								flexWrap: 'wrap',
							},
						}}
					>
						<section
							id="editor"
							style={{
								width: '95%',
								height: '220px',
							}}
						>
							<Textarea maxRows={7} minRows={7} />
							<nav style={{ display: 'flex', justifyContent: 'space-between' }}>
								<Button variant="outlined" startIcon={<AttachmentIcon />}>
									Joindre
								</Button>
								<Button variant="outlined" endIcon={<SendIcon />}>
									Send
								</Button>
							</nav>
						</section>
						<section
							id="messages-box"
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignContent: 'flex-start',
								flexWrap: 'wrap',
								width: '95%',
								flex: 1,
								overflowY: 'scroll',
								borderTop: '1px solid #ccc',
								paddingTop: '10px',
							}}
						>
							<Paper
								id="AgentMessage"
								style={{
									display: 'flex',
									flexDirection: 'row-reverse', //Agent: Avatar on Right
									width: '100%',
									height: 'fit-content',
									padding: '5px',
									marginBottom: '10px',
								}}
							>
								{/* Avatar on the left */}
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										width: '50px',
										justifyContent: 'center',
										alignContent: 'center',
										alignItems: 'center',
									}}
								>
									<Avatar>AG</Avatar>
								</div>

								<div
									id="message"
									style={{
										width: '100%',
										height: 'auto',
									}}
								>
									<article
										style={{
											padding: '5px',
											height: 'fit-content',
											overflowY: 'auto',
										}}
									>
										<p>
											hello, qsdqsdqsdqsd qsd sqd qsd qs dqsd sqdqsd qssd qsd q
											qsdqsd qsqsd qsd qsd qsdq dqs qsd d qsd qs dqs qsd qs dq
											sd qsdqs dqsdqs dsd qsd qsd qsd qs qsdqs dq sd qsd sqd sqd
											sq dqs Kr qsdqsd
										</p>
									</article>
									{/* Date/time above text */}
									<section
										style={{
											textAlign: 'left', // For Agent date on the LEFT
										}}
									>
										10/09/2023 10:25
									</section>
								</div>
							</Paper>
							<Paper
								id="clientMessage"
								style={{
									display: 'flex',
									flexDirection: 'row',
									width: '100%',
									height: 'fit-content',
									padding: '5px',
									marginBottom: '10px',
								}}
							>
								{/* Avatar on the left */}
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										width: '50px',
										justifyContent: 'center',
										alignContent: 'center',
										alignItems: 'center',
									}}
								>
									<Avatar>RB</Avatar>
								</div>

								<div
									id="message"
									style={{
										width: '100%',
										height: 'auto',
									}}
								>
									<article
										style={{
											padding: '5px',
											height: 'fit-content',
											overflowY: 'auto',
										}}
									>
										<p>
											hello, qsdqsdqsdqsd qsd sqd qsd qs dqsd sqdqsd qssd qsd q
											qsdqsd qsqsd qsd qsd qsdq dqs qsd d qsd qs dqs qsd qs dq
											sd qsdqs dqsdqs dsd qsd qsd qsd qs qsdqs dq sd qsd sqd sqd
											sq dqs Kr qsdqsd
										</p>
									</article>
									{/* Date/time above text */}
									<section
										style={{
											textAlign: 'right',
										}}
									>
										10/09/2023 10:25
									</section>
								</div>
							</Paper>
						</section>
					</Drawer>
				</>
			)}
		</>
	)
}

export default EditTask
