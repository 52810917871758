import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import Spinner from '../../../UI/Spinner'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	frFR,
} from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import Chip from '@mui/material/Chip'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import PersonIcon from '@mui/icons-material/Person'
import ContactPageIcon from '@mui/icons-material/ContactPage'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

import EditIcon from '@material-ui/icons/BorderColor'

const USERS = loader('./graphql/users.graphql')

export default function UserList() {
	const { updateName } = useContext(Context)
	useDocumentTitle('Liste des utilisateurs')
	const { data: { users = null } = {}, loading, refetch } = useQuery(USERS)

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
				<Button
					component={Link}
					to="/nouveau-utilisateur/"
					className="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButtonBase-root  css-1knaqv7-MuiButtonBase-root-MuiButton-root"
				>
					<AddIcon className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon" />{' '}
					Nouveau utilisateur
				</Button>
			</GridToolbarContainer>
		)
	}

	const columns = [
		{
			field: 'actions',
			headerName: 'Action',
			filterable: false,
			sortable: false,
			headerClassName: 'listHeader',
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<Link
						to={`/modifier-utilisateur/${thisRow.id}`}
						style={{ margin: '0 auto' }}
						className="listEditBtn"
					>
						<EditIcon className="editBtnIcon" /> Modifier
					</Link>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 150,
			hide: true,
			flex: 1,
			filterable: false,
			sortable: false,
		},
		{
			field: 'color',
			headerName: 'Couleur',
			minWidth: 50,
			renderCell: (params) => {
				const { row } = params
				return (
					<div
						style={{
							margin: '0 auto',
							width: '50px',
							height: '50px',
							borderRadius: '50%',
							backgroundColor: row?.color,
						}}
					></div>
				)
			},
		},
		{
			field: 'userName',
			headerName: "Nom d'utilisateur",
			minWidth: 150,
			flex: 1,
		},
		{ field: 'name', headerName: 'Nom', minWidth: 150, flex: 1 },
		{
			field: 'role',
			headerName: 'Role',
			minWidth: 200,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return (
					<>
						<Stack
							direction="row"
							spacing={1}
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							{row?.roles[0]?.role === 'employee' ? (
								<Chip label="Employé" color="info" icon={<PersonIcon />} />
							) : row?.roles[0]?.role === 'accountant' ? (
								<Chip
									label="Gestionnaire"
									color="info"
									icon={<ContactPageIcon />}
								/>
							) : (
								<Chip
									label="Administrateur"
									color="info"
									icon={<ManageAccountsIcon />}
								/>
							)}
						</Stack>
					</>
				)
			},
			valueGetter: (value) =>
				value?.row?.roles[0]?.role === 'employee'
					? 'Basic'
					: value?.row?.roles[0]?.role === 'admin'
					? 'Administrateur'
					: 'Facturation',
		},
	]

	if (localStorage.getItem('userCreateMsg') !== '') {
		toast.success(localStorage.getItem('userCreateMsg'))
	}

	if (localStorage.getItem('userUpdateMsg') !== '') {
		toast.success(localStorage.getItem('userUpdateMsg'))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('userCreateMsg', '')
			localStorage.setItem('userUpdateMsg', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [users])

	useEffect(() => {
		updateName(
			JSON.stringify([
				{
					level: '1',
					title: 'Liste des utilisateurs',
					url: '/liste-utilisateurs',
				},
			])
		)
	}, [updateName])
	if (loading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-center" transition={Slide} />
			{users && (
				<DataGrid
					disableSelectionOnClick={true}
					localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
					rows={users}
					autoHeight={true}
					columns={columns}
					components={{
						Toolbar: CustomToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}
		</>
	)
}
