import React, { useEffect, useState, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import InputAdornment from '@material-ui/core/InputAdornment'
import SaveIcon from '@material-ui/icons/Save'
import { Button } from '@material-ui/core'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'

import Spinner from '../../../UI/Spinner'
import SystemInfo from '../../../UI/SystemInfo'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

const SERVICE = loader('./graphql/service.graphql')
const UPDATE_SERVICE = loader('./graphql/updateService.graphql')

const EditService = () => {
	const { updateName } = useContext(Context)
	const params = useParams()

	const { data: { service = null } = {}, loading: serviceLoading } = useQuery(
		SERVICE,
		{
			variables: { id: params.id },
		}
	)

	const [updateService, { data, error, loading }] = useMutation(UPDATE_SERVICE)

	const handleSubmit = (e) => {
		e.preventDefault()
		const { name, description, price } = e.target
		return updateService({
			variables: {
				input: {
					id: params.id,
					name: name.value,
					description: description.value,
					price: Number(price.value),
				},
			},
		})
	}

	useEffect(() => {
		updateName(
			JSON.stringify([
				{
					level: '1',
					title: 'Modifier service',
					url: `/modifier-service/${service && service?.id}`,
				},
				{
					level: '2',
					title: `${service && service?.name}`,
					url: `/modifier-service/${service && service?.id}`,
				},
			])
		)
	}, [updateName, service])

	useDocumentTitle(
		service ? 'Modifer le service ' + service?.name : 'Modifer service '
	)

	if (serviceLoading || loading) return <Spinner />

	if (data) {
		localStorage.setItem(
			'serviceUpdateMsg',
			'✔️ Le service a été modifié avec succès ! '
		)
		return <Redirect to="/liste-services" />
	}
	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<FormGroup className="formGrp" row>
					<TextField
						defaultValue={service.name}
						label="Titre"
						name="name"
						size="small"
						margin="normal"
						style={{ width: '500px' }}
					/>
					<TextField
						disabled
						defaultValue={service.odooRef}
						label="Reference"
						name="odooRef"
						size="small"
						margin="normal"
					/>
				</FormGroup>
				<FormGroup className="formGrp" row>
					<TextField
						defaultValue={service.description}
						label="Description"
						name="description"
						size="small"
						margin="normal"
						style={{ width: '500px' }}
					/>

					<TextField
						defaultValue={Number(service.price)}
						label="Prix de vente"
						name="price"
						type="number"
						size="small"
						margin="normal"
						InputProps={{
							endAdornment: <InputAdornment position="end">€</InputAdornment>,
						}}
					/>
				</FormGroup>
				<FormGroup className="formGrp formSubmitBtn" row>
					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
					>
						{' '}
						<SaveIcon />
						&nbsp;Enregistrer{' '}
					</Button>
				</FormGroup>
				<FormGroup className="formGrp" row>
					<SystemInfo
						createdBy={service.createdBy}
						createdAt={service.createdAt}
						updatedBy={service.updatedBy}
						updatedAt={service.updatedAt}
					/>
				</FormGroup>
			</Box>
		</>
	)
}

export default EditService
