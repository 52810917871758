import React, { useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import InputAdornment from '@material-ui/core/InputAdornment'
import SaveIcon from '@material-ui/icons/Save'
import { Button } from '@material-ui/core'

import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'

import Spinner from '../../../UI/Spinner'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

const CREATE_SERVICE = loader('./graphql/createService.graphql')

const CreateService = () => {
	const { updateName } = useContext(Context)
	useDocumentTitle('Nouveau service')

	const [CreateService, { data, error, loading }] = useMutation(CREATE_SERVICE)

	const handleSubmit = (e) => {
		e.preventDefault()
		const { name, price, description } = e.target
		return CreateService({
			variables: {
				input: {
					name: name.value,
					price: Number(price.value),
					description: description.value,
				},
			},
		})
	}

	useEffect(() => {
		updateName(
			JSON.stringify([
				{ level: '1', title: 'Nouveau service', url: '/nouveau-service' },
			])
		)
	}, [updateName])
	if (loading) return <Spinner />

	if (data) {
		localStorage.setItem(
			'serviceCreateMsg',
			'✔️ Le service a été crée avec succès ! '
		)
		return <Redirect to="/liste-services" />
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<FormGroup className="formGrp" row>
					<TextField
						label="Titre"
						name="name"
						size="small"
						margin="normal"
						style={{ width: '500px' }}
					/>
				</FormGroup>
				<FormGroup className="formGrp" row>
					<TextField
						label="Description"
						name="description"
						size="small"
						margin="normal"
						style={{ width: '500px' }}
					/>

					<TextField
						label="Prix de vente"
						name="price"
						type="number"
						size="small"
						margin="normal"
						InputProps={{
							endAdornment: <InputAdornment position="end">€</InputAdornment>,
						}}
					/>
				</FormGroup>
				<FormGroup className="formGrp formSubmitBtn" row>
					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
					>
						{' '}
						<SaveIcon />
						&nbsp;Enregistrer{' '}
					</Button>
				</FormGroup>
			</Box>
		</>
	)
}

export default CreateService
