import React, { useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import InputAdornment from '@material-ui/core/InputAdornment'
import SaveIcon from '@material-ui/icons/Save'
import { Button } from '@material-ui/core'

import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Chip from '@mui/material/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'

import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'

import Spinner from '../../../UI/Spinner'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

const CREATE_TAG = loader('./graphql/createTag.graphql')

const CreateTag = () => {
	const { updateName } = useContext(Context)
	useDocumentTitle('Nouveau tag')

	const [CreateTag, { data, error, loading }] = useMutation(CREATE_TAG)

	const handleSubmit = (e) => {
		e.preventDefault()
		const { title, color } = e.target
		return CreateTag({
			variables: {
				input: {
					title: title.value,
					color: color.value,
				},
			},
		})
	}

	useEffect(() => {
		updateName(
			JSON.stringify([
				{ level: '1', title: 'Nouveau tag', url: '/nouveau-tag' },
			])
		)
	}, [updateName])
	if (loading) return <Spinner />

	if (data) {
		localStorage.setItem('tagCreateMsg', '✔️ Le tag a été crée avec succès ! ')
		return <Redirect to="/liste-tags" />
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<FormGroup className="formGrp" row>
					<TextField
						label="Tag"
						name="title"
						size="small"
						margin="normal"
						style={{ width: '300px' }}
						inputProps={{ maxLength: 20 }}
					/>

					<FormControl style={{ m: 1, width: '300px' }} label="Style">
						<InputLabel>Style</InputLabel>
						<Select
							labelId="Style"
							label="Style"
							name="color"
							id="Tag"
							style={{ m: 1, width: '300px', textAlign: 'center' }}
							defaultValue="primary"
						>
							<MenuItem
								value="primary"
								style={{ display: 'flex', justifyContent: 'center' }}
							>
								<Chip
									label="Style 1"
									color="primary"
									onDelete=""
									style={{ m: 1, width: '200px', textAlign: 'center' }}
								/>
							</MenuItem>
							<MenuItem
								value="secondary"
								style={{ display: 'flex', justifyContent: 'center' }}
							>
								<Chip
									label="Style 2"
									color="secondary"
									onDelete=""
									style={{ m: 1, width: '200px', textAlign: 'center' }}
								/>
							</MenuItem>
							<MenuItem
								value="error"
								style={{ display: 'flex', justifyContent: 'center' }}
							>
								<Chip
									label="Style 3"
									color="error"
									onDelete=""
									style={{ m: 1, width: '200px', textAlign: 'center' }}
								/>
							</MenuItem>
							<MenuItem
								value="info"
								style={{ display: 'flex', justifyContent: 'center' }}
							>
								<Chip
									label="Style 4"
									color="info"
									onDelete=""
									style={{ m: 1, width: '200px', textAlign: 'center' }}
								/>
							</MenuItem>
							<MenuItem
								value="success"
								style={{ display: 'flex', justifyContent: 'center' }}
							>
								<Chip
									label="Style 5"
									color="success"
									onDelete=""
									style={{ m: 1, width: '200px', textAlign: 'center' }}
								/>
							</MenuItem>
							<MenuItem
								value="warning"
								style={{ display: 'flex', justifyContent: 'center' }}
							>
								<Chip
									label="Style 6"
									color="warning"
									onDelete=""
									style={{ m: 1, width: '200px', textAlign: 'center' }}
								/>
							</MenuItem>
							<MenuItem
								value="default"
								style={{ display: 'flex', justifyContent: 'center' }}
							>
								<Chip
									label="Style 7"
									color="default"
									onDelete=""
									style={{ m: 1, width: '200px', textAlign: 'center' }}
								/>
							</MenuItem>
						</Select>
					</FormControl>
				</FormGroup>

				<FormGroup className="formGrp formSubmitBtn" row>
					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
					>
						{' '}
						<SaveIcon />
						&nbsp;Enregistrer{' '}
					</Button>
				</FormGroup>
			</Box>
		</>
	)
}

export default CreateTag
