import React, { useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery, useMutation } from '@apollo/client'
import Spinner from '../../../UI/Spinner'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	frFR,
} from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../../UI/toastify/ReactToastify.min.css'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'
import '../../../../asset/style.css'
import EditIcon from '@material-ui/icons/BorderColor'
import DeleteIcon from '@mui/icons-material/Delete'

const ALL_TAGS = loader('./graphql/allTags.graphql')
const REMOVE_TAG = loader('./graphql/removeTag.graphql')

export default function UserTag() {
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [tagIdToDelete, setTagIdToDelete] = useState(null)
	const { updateName } = useContext(Context)
	useDocumentTitle('Liste des tags')

	const { data: { allTags = null } = {}, loading, refetch } = useQuery(ALL_TAGS)
	const [
		removeTag,
		{ data: removeTagData, error: removeTagError, loading: removeTagloading },
	] = useMutation(REMOVE_TAG)

	const openDialog = () => {
		setIsDialogOpen(true)
	}

	const closeDialog = () => {
		setIsDialogOpen(false)
	}

	const handleRemoveTag = ({ id }) => {
		setTagIdToDelete(id)
		openDialog()
	}

	const handleRemoveTagConfirmation = (tagId) => {
		removeTag({
			variables: {
				input: {
					id: tagId,
				},
			},
			update: (cache) => {
				// Manually update the cache to remove the deleted tag
				const cachedData = cache.readQuery({ query: ALL_TAGS })
				if (cachedData) {
					const updatedData = {
						allTags: cachedData.allTags.filter((tag) => tag.id !== tagId),
					}
					cache.writeQuery({ query: ALL_TAGS, data: updatedData })
				}
			},
		})
			.then(() => {
				toast.success('Le tag a été supprimée avec succès.')
			})
			.catch((error) => {
				toast.error(
					"Une erreur s'est produite lors de la suppression de la balise."
				)
			})
	}

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
				<Button
					component={Link}
					to="/nouveau-tag/"
					className="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButtonBase-root css-1knaqv7-MuiButtonBase-root-MuiButton-root"
				>
					<AddIcon className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon" />{' '}
					Nouveau tag
				</Button>
			</GridToolbarContainer>
		)
	}

	const columns = [
		{
			field: 'actions',
			headerName: 'Action',
			filterable: false,
			sortable: false,
			minWidth: 300,
			headerClassName: 'listHeader',
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<>
						<Link
							to={`/modifier-tag/${thisRow.id}`}
							style={{ margin: '0 auto' }}
							className="listEditBtn"
						>
							<EditIcon className="editBtnIcon" /> Modifier
						</Link>
						<Button
							onClick={() => handleRemoveTag({ id: thisRow.id })}
							style={{ margin: '0 auto' }}
							className="listremoveBtn"
							title={thisRow.id}
							variant="contained"
							color="error"
						>
							<DeleteIcon className="editBtnIcon" /> Supprimer
						</Button>
					</>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 150,
			hide: true,
			flex: 1,
			filterable: false,
			sortable: false,
		},
		{
			field: 'title',
			headerName: 'Tag',
			minWidth: 250,
			flex: 1,
		},
		{
			field: 'color',
			headerName: 'Style',
			minWidth: 250,
			renderCell: (params) => {
				const { row } = params
				return (
					<Chip
						color={row.color}
						label={
							row.color === 'primary'
								? 'Style 1'
								: row.color === 'secondary'
								? 'Style 2'
								: row.color === 'error'
								? 'Style 3'
								: row.color === 'info'
								? 'Style 4'
								: row.color === 'success'
								? 'Style 5'
								: row.color === 'warning'
								? 'Style 6'
								: row.color === 'default'
								? 'Style 7'
								: ''
						}
						style={{ m: 1, width: '200px', textAlign: 'center' }}
					/>
				)
			},
		},
	]

	if (localStorage.getItem('tagCreateMsg') !== '') {
		toast.success(localStorage.getItem('tagCreateMsg'))
	}

	if (localStorage.getItem('tagUpdateMsg') !== '') {
		toast.success(localStorage.getItem('tagUpdateMsg'))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('tagCreateMsg', '')
			localStorage.setItem('tagUpdateMsg', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [allTags])

	useEffect(() => {
		updateName(
			JSON.stringify([
				{
					level: '1',
					title: 'Liste des tags',
					url: '/liste-tags',
				},
			])
		)
	}, [updateName])

	if (loading || removeTagloading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-center" transition={Slide} />
			{allTags && (
				<DataGrid
					disableSelectionOnClick={true}
					localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
					rows={allTags}
					autoHeight={true}
					columns={columns}
					components={{
						Toolbar: CustomToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}

			<Dialog
				open={isDialogOpen}
				onClose={closeDialog}
				aria-labelledby="confirmation-dialog-title"
			>
				<DialogTitle id="confirmation-dialog-title">
					Confirmer la suppression
				</DialogTitle>
				<DialogContent>
					Êtes-vous sûr de vouloir supprimer cette balise ?
				</DialogContent>
				<DialogActions>
					<Button onClick={closeDialog} color="primary">
						Annuler
					</Button>
					<Button
						onClick={() => {
							handleRemoveTagConfirmation(tagIdToDelete)
							closeDialog()
						}}
						color="primary"
					>
						Supprimer
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}
