import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

import './index.scss'

/** Spinning icon to show that the page is loading. */
const Spinner = () => (
	<div className="container">
		<FontAwesomeIcon icon={faSpinner} color="secondary" spin />
		<h1 color="secondary" style={{ fontSize: '18px' }}>
			{' '}
			Chargement en cours...
		</h1>
	</div>
)

export default Spinner
