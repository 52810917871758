import React, { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import InputAdornment from '@material-ui/core/InputAdornment'
import SaveIcon from '@material-ui/icons/Save'
import { Button } from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'

import CallIn from '@mui/icons-material/CallMissedOutgoing'
import CallOut from '@mui/icons-material/CallMissed'
import CallIcon from '@mui/icons-material/Call'

import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'

import Spinner from '../../../UI/Spinner'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

const CREATE_CALL = loader('./graphql/createCall.graphql')
const ALL_CLIENTS = loader('./graphql/allClients.graphql')
const ALL_TASKS = loader('./graphql/allTasks.graphql')

const CreateCall = () => {
	const { updateName } = useContext(Context)
	useDocumentTitle('Nouveau service')
	const [clientId, setClientId] = useState(null)
	const [taskId, setTaskId] = useState(null)
	const [callType, setCallType] = useState('in')

	const [CreateCall, { data, error, loading }] = useMutation(CREATE_CALL)
	const { data: { allClients = null } = {}, loading: clientsLoading } =
		useQuery(ALL_CLIENTS)
	const { data: { allTasks = null } = {}, loading: tasksLoading } =
		useQuery(ALL_TASKS)

	const handleSubmit = (e) => {
		e.preventDefault()
		const { comment } = e.target
		return CreateCall({
			variables: {
				input: {
					callType: callType,
					clientId: clientId,
					taskId: taskId,
					comment: comment.value,
				},
			},
		})
	}

	useEffect(() => {
		updateName(
			JSON.stringify([
				{ level: '1', title: 'Nouveau call', url: '/nouveau-call' },
			])
		)
	}, [updateName])

	if (loading || clientsLoading || tasksLoading) return <Spinner />

	if (data) {
		localStorage.setItem(
			'callCreateMsg',
			'✔️ Le call a été crée avec succès ! '
		)
		return <Redirect to="/liste-calls" />
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<FormGroup className="formGrp" row>
					{allClients && (
						<Autocomplete
							onChange={(i, value) => setClientId(value?.id)}
							options={allClients}
							getOptionLabel={(option) => option.company}
							renderOption={(props, option) => (
								<Box
									key={option.id}
									component="li"
									sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
									{...props}
								>
									&nbsp;&nbsp;{option.company}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Client"
									name="client"
									size="small"
									required
									margin="dense"
									style={{ width: '500px' }}
								/>
							)}
						/>
					)}
					{allTasks && (
						<Autocomplete
							disabled={!clientId}
							onChange={(i, value) => setTaskId(value?.id)}
							options={allTasks.filter((tsk) => tsk?.client?.id === clientId)}
							getOptionLabel={(option) => option.number}
							renderOption={(props, option) => (
								<Box
									key={option.id}
									component="li"
									sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
									{...props}
								>
									&nbsp;&nbsp;{option.number}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Tâche"
									name="task"
									size="small"
									required
									margin="dense"
									helperText={
										!clientId && 'Veuillez sélectionner un client en premier.'
									}
									style={{ width: '500px' }}
								/>
							)}
						/>
					)}
				</FormGroup>
				<FormGroup className="formGrp" row>
					<FormControl
						className="formSelect"
						size="small"
						margin="dense"
						style={{ width: '500px' }}
					>
						<InputLabel>Type</InputLabel>
						<Select
							labelId="Status"
							name="Status"
							defaultValue="in"
							onChange={({ target }) => setCallType(target.value)}
						>
							<MenuItem key="in" value="in">
								<span
									style={{
										display: 'flex',
										alignItems: 'center',
										marginLeft: '20px',
									}}
								>
									Entrant &nbsp;&nbsp;
									<CallIcon />
									<CallOut />
								</span>
							</MenuItem>
							<MenuItem key="out" value="out">
								<span
									style={{
										display: 'flex',
										alignContent: 'center',
										marginLeft: '20px',
									}}
								>
									Sortant &nbsp;&nbsp;
									<CallIcon />
									<CallIn />
								</span>
							</MenuItem>
						</Select>
					</FormControl>
					<TextField
						label="Description"
						name="comment"
						size="small"
						margin="normal"
						style={{ width: '500px' }}
					/>
				</FormGroup>
				<FormGroup className="formGrp formSubmitBtn" row>
					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
						disabled={!taskId || !clientId ? true : false}
					>
						{' '}
						<SaveIcon />
						&nbsp;Enregistrer{' '}
					</Button>
				</FormGroup>
			</Box>
		</>
	)
}

export default CreateCall
