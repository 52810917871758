import React, { useEffect, useState, useContext } from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import HomeIcon from '@mui/icons-material/Home'

import { Context } from '../../breadcrumsContext'

import '../../../asset/style.css'

const BreadcrumbsCompennent = () => {
	const { name } = useContext(Context)

	const brd = name !== '' ? JSON.parse(name) : []

	return (
		<div
			className="Container breadcrumbContainer"
			style={{ margin: '0 auto', marginRight: '50px' }}
		>
			<Breadcrumbs aria-label="breadcrumb" style={{ color: '#000' }}>
				<Link
					sx={{ display: 'flex', alignItems: 'center' }}
					underline="hover"
					color="inherit"
					href="/home"
				>
					<HomeIcon sx={{ mr: 1 }} fontSize="inherit" /> Acceuil
				</Link>
				{brd &&
					brd.map((elm, i) => (
						<Link underline="hover" color="inherit" href={elm.url} key={i}>
							{elm.title}
						</Link>
					))}
			</Breadcrumbs>
		</div>
	)
}

export default BreadcrumbsCompennent
