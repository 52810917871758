import React, { useEffect, useState, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import InputAdornment from '@material-ui/core/InputAdornment'
import SaveIcon from '@material-ui/icons/Save'
import { Button } from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'

import CallIn from '@mui/icons-material/CallMissedOutgoing'
import CallOut from '@mui/icons-material/CallMissed'
import CallIcon from '@mui/icons-material/Call'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'

import Spinner from '../../../UI/Spinner'
import SystemInfo from '../../../UI/SystemInfo'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

const CALL = loader('./graphql/call.graphql')
const UPDATE_CALL = loader('./graphql/updateCall.graphql')
const ALL_CLIENTS = loader('./graphql/allClients.graphql')
const ALL_TASKS = loader('./graphql/allTasks.graphql')

const EditCall = () => {
	const { updateName } = useContext(Context)
	const params = useParams()
	const [clientId, setClientId] = useState(null)
	const [taskId, setTaskId] = useState(null)
	const [callType, setCallType] = useState(null)

	const { data: { call = null } = {}, loading: callLoading } = useQuery(CALL, {
		variables: { id: params.id },
	})

	const { data: { allClients = null } = {}, loading: clientsLoading } =
		useQuery(ALL_CLIENTS)

	const { data: { allTasks = null } = {}, loading: tasksLoading } =
		useQuery(ALL_TASKS)

	const [updateCall, { data, error, loading }] = useMutation(UPDATE_CALL)

	const handleSubmit = (e) => {
		e.preventDefault()
		const { comment } = e.target
		return updateCall({
			variables: {
				input: {
					id: params.id,
					callType: callType,
					clientId: clientId,
					taskId: taskId,
					comment: comment.value,
				},
			},
		})
	}

	useEffect(() => {
		if (call) {
			setClientId(call?.client?.id || null)
			setTaskId(call?.task?.id || null)
			setCallType(call?.callType || null)
		}
		updateName(
			JSON.stringify([
				{
					level: '1',
					title: 'Modifier call',
					url: `/modifier-call/${call && call?.id}`,
				},
				{
					level: '2',
					title: `${
						call && call?.client?.company + ' - ' + call?.task?.number
					}`,
					url: `/modifier-call/${call && call?.id}`,
				},
			])
		)
	}, [updateName, call])

	useDocumentTitle(
		call
			? 'Modifer call ' + call?.client?.company + ' - ' + call?.task?.number
			: 'Modifer call '
	)

	if (callLoading || loading || clientsLoading || tasksLoading)
		return <Spinner />

	if (data) {
		localStorage.setItem(
			'callUpdateMsg',
			'✔️ Le call a été modifié avec succès ! '
		)
		return <Redirect to="/liste-calls" />
	}
	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<FormGroup className="formGrp" row>
					{allClients && (
						<Autocomplete
							onChange={(event, value) => {
								setClientId(value?.id)
								setTaskId(null)
							}}
							defaultValue={allClients.find(
								({ id }) => id === call?.client?.id
							)}
							options={allClients}
							getOptionLabel={(option) => option.company}
							renderOption={(props, option) => (
								<Box
									key={option.id}
									component="li"
									sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
									{...props}
								>
									&nbsp;&nbsp;{option.company}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Client"
									name="client"
									size="small"
									required
									margin="dense"
									style={{ width: '500px' }}
								/>
							)}
						/>
					)}
					{allTasks && (
						<Autocomplete
							disabled={!clientId}
							onChange={(i, value) => setTaskId(value?.id)}
							defaultValue={allTasks.find(({ id }) => id === call?.task?.id)}
							options={allTasks.filter((tsk) => tsk?.client?.id === clientId)}
							getOptionLabel={(option) => (!taskId ? '' : option.number)}
							renderOption={(props, option) => (
								<Box
									key={option.id}
									component="li"
									sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
									{...props}
								>
									&nbsp;&nbsp;{option.number}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Tâche"
									name="task"
									size="small"
									required
									margin="dense"
									helperText={
										!clientId && 'Veuillez sélectionner un client en premier.'
									}
									style={{ width: '500px' }}
								/>
							)}
						/>
					)}
				</FormGroup>
				<FormGroup className="formGrp" row>
					<FormControl style={{ width: '500px' }} label="Type">
						<InputLabel>Type</InputLabel>
						<Select
							labelId="Type"
							label="Type"
							name="type"
							id="type"
							style={{ width: '500px' }}
							defaultValue={call && call.callType}
							onChange={({ target }) => setCallType(target.value)}
						>
							<MenuItem key="in" value="in">
								<span
									style={{
										display: 'flex',
										alignItems: 'center',
										marginLeft: '20px',
									}}
								>
									Entrant &nbsp;&nbsp;
									<CallIcon />
									<CallOut />
								</span>
							</MenuItem>
							<MenuItem key="out" value="out">
								<span
									style={{
										display: 'flex',
										alignContent: 'center',
										marginLeft: '20px',
									}}
								>
									Sortant &nbsp;&nbsp;
									<CallIcon />
									<CallIn />
								</span>
							</MenuItem>
						</Select>
					</FormControl>

					<TextField
						defaultValue={call && call.comment}
						label="Description"
						name="comment"
						size="small"
						margin="normal"
						style={{ width: '500px' }}
					/>
				</FormGroup>
				<FormGroup className="formGrp formSubmitBtn" row>
					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
						disabled={!taskId || !clientId ? true : false}
					>
						{' '}
						<SaveIcon />
						&nbsp;Enregistrer{' '}
					</Button>
				</FormGroup>
				<FormGroup className="formGrp" row>
					<SystemInfo
						createdBy={call && call.createdBy}
						createdAt={call && call.createdAt}
						updatedBy={call && call.updatedBy}
						updatedAt={call && call.updatedAt}
					/>
				</FormGroup>
			</Box>
		</>
	)
}

export default EditCall
