import React, { createContext, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
// import CircularProgress from '@material-ui/core/CircularProgress'

import LoadingBar from '../UI/Spinner'

import { UserConsumer } from '../breadcrumsContext'

const CURRENT_USER = loader('../pages/Home/graphql/currentUser.graphql')

export const CurrentUserContext = createContext()

export const CurrentUserProvider = ({ children }) => {
	const { data: { currentUser = null } = {}, loading } = useQuery(CURRENT_USER)
	if (loading) return <LoadingBar />

	return (
		<CurrentUserContext.Provider value={currentUser}>
			{children}
		</CurrentUserContext.Provider>
	)
}

export const useCurrentUser = () => useContext(CurrentUserContext)
