import React, { useState, useEffect, useContext } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import SaveIcon from '@material-ui/icons/Save'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import IconButton from '@material-ui/core/IconButton'
import FilledInput from '@material-ui/core/FilledInput'
import InputAdornment from '@material-ui/core/InputAdornment'

import { Button } from '@material-ui/core'
import Spinner from '../../../UI/Spinner'
import SystemInfo from '../../../UI/SystemInfo'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

const CREATE_USER = loader('./graphql/createUser.graphql')
const ALL_ROLES = loader('./graphql/allRoles.graphql')

const CreateUser = () => {
	const { updateName } = useContext(Context)
	useDocumentTitle('Nouveau utilisateur')
	const [values, setValues] = useState({
		showPassword: false,
	})

	const [createUser, { data, error, loading }] = useMutation(CREATE_USER)
	const { data: { allRoles = null } = {}, loading: rolesLoading } =
		useQuery(ALL_ROLES)

	const handleSubmit = (e, s) => {
		e.preventDefault()
		const { name, username, password, role, color } = e.target
		return createUser({
			variables: {
				input: {
					name: name.value,
					userName: username.value,
					password: password.value,
					roleId: role.value,
					color: color.value,
				},
			},
		})
	}

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value })
	}

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword })
	}

	const handleMouseDownPassword = (event) => {
		event.preventDefault()
	}

	useEffect(() => {
		updateName(
			JSON.stringify([
				{
					level: '1',
					title: 'Nouveau utilisateur',
					url: '/nouveau-utilisateur',
				},
			])
		)
	}, [updateName])

	if (data?.createUser) return <Redirect to="/liste-utilisateurs" />

	if (loading || rolesLoading) return <Spinner />

	if (data) {
		localStorage.setItem(
			'userCreateMsg',
			"✔️ L'utilisateur a été crée avec succès ! "
		)
		return <Redirect to="/liste-utilisateurs" />
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<FormGroup className="formGrp" row>
					<TextField
						label="Nom"
						name="name"
						size="small"
						margin="normal"
						helperText="Le nom qui sera inséré dans ce champ servira à créer la signature de l'e-mail."
					/>
					<TextField
						label="Nom d'utilisateur"
						name="username"
						size="small"
						margin="normal"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<AccountCircle />
								</InputAdornment>
							),
						}}
					/>
					<TextField
						label="Couleur"
						name="color"
						type="color"
						size="small"
						margin="normal"
					/>
				</FormGroup>
				<FormGroup className="formGrp" row>
					<FormControl className="formSelect" size="small" margin="normal">
						<InputLabel>Roles</InputLabel>
						{allRoles && (
							<Select
								labelId="Roles"
								name="role"
								defaultValue={
									allRoles.find((elm) => elm.role === 'employee').id
								}
							>
								{allRoles.map(({ role, id }) => {
									if (role === 'accountant') {
										return (
											<MenuItem key={id} value={id}>
												Gestionaire
											</MenuItem>
										)
									} else if (role === 'admin') {
										return (
											<MenuItem key={id} value={id}>
												Administrateur
											</MenuItem>
										)
									} else if (role === 'employee') {
										return (
											<MenuItem key={id} value={id}>
												Employé
											</MenuItem>
										)
									}
								})}
							</Select>
						)}
					</FormControl>

					<FormControl variant="filled">
						<InputLabel>Mot de passe</InputLabel>
						<FilledInput
							name="password"
							type={values.showPassword ? 'text' : 'password'}
							value={values.password}
							onChange={handleChange('password')}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{values.showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				</FormGroup>
				<FormGroup className="formGrp formSubmitBtn" row>
					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
					>
						{' '}
						<SaveIcon />
						&nbsp;Enregistrer{' '}
					</Button>
				</FormGroup>
			</Box>
		</>
	)
}

export default CreateUser
