import React, { useEffect, useState, useRef, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import { useCurrentUser } from '../../../currentUserContext'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import SaveIcon from '@material-ui/icons/Save'
import { Button } from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'

import Tooltip from '@mui/material/Tooltip'

import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'

import Spinner from '../../../UI/Spinner'
import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

const CREATE_TASK = loader('./graphql/createTask.graphql')
const ALL_CLIENTS = loader('./graphql/allClients.graphql')
const ALL_SERVICES = loader('./graphql/allServices.graphql')
const USERS = loader('./graphql/users.graphql')

const CreateTask = () => {
	const { updateName } = useContext(Context)
	useDocumentTitle('Nouvelle tâche')
	const currentUser = useCurrentUser()
	const [assignedTo, seAssignedTo] = useState()
	const [chosenStatus, setChosenStatus] = useState('inProgress')
	const [clientId, setClientId] = useState(null)
	const [serviceId, setServiceId] = useState(null)
	const [listServices, setListServices] = useState([])
	const [values, setValues] = useState([])
	const autoC = useRef(null)

	const isAdmin =
		currentUser && currentUser.roles.find(({ role }) => role === 'admin')

	const { data: { allClients = null } = {}, loading: clientsLoading } =
		useQuery(ALL_CLIENTS)
	const { data: { allServices = null } = {}, loading: serviceLoading } =
		useQuery(ALL_SERVICES)
	const { data: { users = null } = {}, loading: usersLoading } = useQuery(USERS)

	const [createTask, { data, error, loading }] = useMutation(CREATE_TASK)

	const handleSubmit = (e) => {
		e.preventDefault()
		return createTask({
			variables: {
				input: {
					clientId,
					services: listServices?.map(({ serviceId, quantity, remark }) => ({
						serviceId,
						quantity: Number(quantity),
						remark,
					})),
					status: chosenStatus,
					assignedTo: assignedTo || currentUser.id,
				},
			},
		})
	}

	const handleAddService = (e) => {
		e.preventDefault()
		const { service, quantity, remark } = e.target

		setListServices((obj) => [
			...obj,
			{
				serviceId: serviceId,
				service: values.service,
				quantity: values.quantity || 1,
				remark: values.remark || '',
			},
		])

		const serviceClear = autoC.current.getElementsByClassName(
			'MuiAutocomplete-clearIndicator'
		)[0]
		serviceClear.click()
		remark.value = ''
		quantity.value = 1
	}

	useEffect(() => {
		updateName(
			JSON.stringify([
				{ level: '1', title: 'Nouvelle tâche', url: '/nouvelle-tache' },
			])
		)
	}, [updateName])

	if (!currentUser) return <Redirect to="/" />

	if (loading || clientsLoading || serviceLoading || usersLoading)
		return <Spinner />

	if (data) {
		localStorage.setItem('contactState', '✔️ Contact created successfully ! ')
		return <Redirect to="/liste-taches" />
	}

	return (
		<>
			<Box className="mainZone">
				<FormGroup className="formGrp" row>
					{allClients && (
						<Autocomplete
							onChange={(i, value) => setClientId(value?.id)}
							options={allClients}
							getOptionLabel={(option) => option.company}
							renderOption={(props, option) => (
								<Box
									key={option.id}
									component="li"
									sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
									{...props}
								>
									&nbsp;&nbsp;{option.company}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Client"
									name="client"
									size="small"
									required
									margin="dense"
									style={{ width: '500px' }}
								/>
							)}
						/>
					)}

					{users && (
						<FormControl
							className="formSelect"
							size="small"
							margin="dense"
							disabled={!isAdmin}
						>
							<InputLabel>Assigné à</InputLabel>
							<Select
								label="Assigné à"
								name="assignedTo"
								defaultValue={currentUser.id}
								onChange={({ target }) => seAssignedTo(target.value)}
							>
								{users.map(({ id, userName }) => (
									<MenuItem key={id} value={id}>
										{userName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					<FormControl
						className="formSelect"
						size="small"
						margin="dense"
						disabled={!isAdmin}
					>
						<InputLabel>Status</InputLabel>
						<Select
							labelId="Status"
							name="Status"
							defaultValue="inProgress"
							onChange={({ target }) => setChosenStatus(target.value)}
						>
							<MenuItem key="inProgress" value="inProgress">
								En cours
							</MenuItem>
							<MenuItem key="pendding" value="pendding">
								En attente
							</MenuItem>
							<MenuItem key="completed" value="completed">
								Terminer
							</MenuItem>
						</Select>
					</FormControl>
				</FormGroup>
				<Paper
					style={{
						width: '100%',
						height: '100px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						padding: ' 0 20px',
						marginBottom: '20px',
					}}
				>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={handleAddService}
						style={{
							width: '100%',
							height: '100px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							padding: ' 0 20px',
							marginBottom: '20px',
						}}
					>
						<FormGroup className="formGrp" row>
							<Autocomplete
								ref={autoC}
								onChange={(i, value) => {
									setValues({ ...values, service: value?.name })
									setServiceId(value?.id)
								}}
								options={
									listServices.length
										? allServices.filter(
												({ id }) =>
													!listServices.find(
														({ serviceId }) => serviceId === id
													)
										  )
										: allServices
								}
								style={{ marginRight: '20px' }}
								getOptionLabel={(option) => option.name}
								renderOption={(props, option) => (
									<Box
										key={option.id}
										component="li"
										sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
										{...props}
									>
										&nbsp;&nbsp;{option.name}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Services"
										name="service"
										size="small"
										margin="dense"
									/>
								)}
							/>
							<TextField
								onChange={(e) => {
									setValues({ ...values, quantity: e.target.value })
								}}
								label="Quantité"
								name="quantity"
								defaultValue={1}
								size="small"
								type="number"
								margin="dense"
								inputProps={{ min: 0 }}
							/>
							<TextField
								onChange={(e) => {
									setValues({ ...values, remark: e.target.value })
								}}
								label="Remarque"
								name="remark"
								size="small"
								margin="dense"
								style={{ marginRight: '20px', width: '500px' }}
							/>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={serviceId ? false : true}
							>
								+ Ajouter
							</Button>
						</FormGroup>
					</Box>
				</Paper>
				<Paper
					sx={{ width: '100%', maxHeight: '300px', overflowY: 'scroll', mb: 2 }}
				>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell style={{ fontWeight: 'bold' }}>Services</TableCell>
								<TableCell align="right" style={{ fontWeight: 'bold' }}>
									Quantity
								</TableCell>
								<TableCell style={{ fontWeight: 'bold' }}>Remarque</TableCell>
								<TableCell align="right"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{listServices &&
								listServices.map((elm) => {
									return (
										<TableRow key={elm?.serviceId}>
											<TableCell component="th" scope="row">
												{elm?.service}
											</TableCell>
											<TableCell align="right">{elm?.quantity}</TableCell>
											<TableCell>{elm?.remark}</TableCell>
											<TableCell align="right">
												<Tooltip title="Retiré">
													<IconButton
														edge="end"
														aria-label="delete"
														onClick={() =>
															setListServices(
																listServices.filter(
																	(obj) => obj.serviceId !== elm.serviceId
																)
															)
														}
													>
														<DeleteIcon />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									)
								})}
						</TableBody>
					</Table>{' '}
				</Paper>
				<FormGroup className="formGrp formSubmitBtn" row>
					<Button
						disabled={clientId && listServices.length > 0 ? false : true}
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
						onClick={handleSubmit}
					>
						{' '}
						<SaveIcon />
						&nbsp;Enregistrer{' '}
					</Button>
				</FormGroup>
			</Box>
		</>
	)
}

export default CreateTask
