import React, { useContext, useEffect } from 'react'
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import Spinner from '../../../UI/Spinner'
import Stack from '@mui/material/Stack'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

import EditIcon from '@material-ui/icons/BorderColor'

const ALL_CLIENTS = loader('./graphql/allClients.graphql')

export default function ServiceList() {
	const { updateName } = useContext(Context)
	useDocumentTitle('Liste des clients avec des tâches active')

	const {
		data: { allClients = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_CLIENTS)

	const columns = [
		{
			field: 'actions',
			headerName: 'Action',
			flex: 1,
			filterable: false,
			sortable: false,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<Link
						to={`/taches-client/${thisRow.id}`}
						style={{ margin: '0 auto' }}
						className="listEditBtn"
					>
						<EditIcon className="editBtnIcon" /> Crée une facture
					</Link>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 150,
			hide: true,
			flex: 1,
			filterable: false,
			sortable: false,
		},
		{ field: 'company', headerName: 'Client', minWidth: 150, flex: 1 },
		{
			field: 'unpaidService',
			headerName: 'Taches',
			minWidth: 150,
			flex: 1,
			valueGetter: ({ value }) => {
				// return [...new Set(value.map((element) => element['id']))].length
			},
		},

		{
			field: 'unpaidServices',
			headerName: 'Total des Services',
			minWidth: 200,
			flex: 1,
			valueGetter: ({ value }) => {
				return `${value
					.map(({ finalPrice, quantity }) => finalPrice * quantity)
					.reduce((a, b) => a + b)} €`
			},
		},
	]

	// if (localStorage.getItem('contactState') !== '') {
	// 	toast.success(localStorage.getItem('contactState'))
	// }

	// if (localStorage.getItem('CreateContactState') !== '') {
	// 	toast.success(localStorage.getItem('CreateContactState'))
	// }

	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		localStorage.setItem('contactState', '')
	// 	}, 1000)
	// 	return () => clearTimeout(timer)
	// }, [])

	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		localStorage.setItem('CreateContactState', '')
	// 	}, 1000)
	// 	return () => clearTimeout(timer)
	// }, [])

	useEffect(() => {
		updateName(
			JSON.stringify([
				{
					level: '1',
					title: 'Liste des clients avec des tâche active',
					url: '/liste-client-avec-active-taches',
				},
			])
		)
	}, [updateName])

	useEffect(() => {
		refetch()
	}, [allClients])

	if (loading) return <Spinner />

	var newArray = []
	allClients.map((elm) => {
		if (elm.unpaidServices.length > 0) newArray.push(elm)
	})

	return (
		<>
			{allClients && (
				<DataGrid
					disableSelectionOnClick={true}
					localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
					rows={newArray}
					autoHeight={true}
					columns={columns}
					components={{
						Toolbar: GridToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}
		</>
	)
}
