import React from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import * as moment from 'moment'

const SystemInfo = (props) => {
	return (
		<Accordion style={{ width: '100%' }}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
			>
				<Typography>Information system</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<div
					style={{
						divstStyle: 'none',
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'row',
						flexWrap: 'wrap',
						padding: '0px 20px',
					}}
				>
					<div>
						<strong>Creé par : </strong>
						<span>{props?.createdBy?.userName}</span>
					</div>
					<div>
						<strong>Creé à : </strong>
						<span>
							{moment(props?.createdAt, 'x').format('DD/MM/YY HH:mm:ss')}
						</span>
					</div>
					<div>
						<strong>Modifier par :</strong>
						<span style={{ textTransform: 'capitalize' }}>
							{props?.updatedBy?.userName}
						</span>
					</div>
					<div>
						<strong>Modifier à : </strong>
						<span>
							{moment(props?.updatedAt, 'x').format('DD/MM/YY HH:mm:ss')}
						</span>
					</div>
				</div>
			</AccordionDetails>
		</Accordion>
	)
}

export default SystemInfo
