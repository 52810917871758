import React, { useContext, useEffect } from 'react'
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	frFR,
} from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery, useLazyQuery } from '@apollo/client'
import Spinner from '../../../UI/Spinner'
import Stack from '@mui/material/Stack'

import { Context } from '../../../breadcrumsContext'
import { useCurrentUser } from '../../../currentUserContext'
import { useDocumentTitle } from '../../../documentTitle'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import '../../../../asset/style.css'

import EditIcon from '@material-ui/icons/BorderColor'
import LoopIcon from '@mui/icons-material/Loop'

const ALL_CLIENTS = loader('./graphql/allClients.graphql')
const IMPORT_CLIENTS = loader('./graphql/importClientsFromOdoo.graphql')

export default function ClientList() {
	const { updateName } = useContext(Context)
	const currentUser = useCurrentUser()
	useDocumentTitle('Liste des clients')

	const isAdmin =
		currentUser && currentUser.roles.find(({ role }) => role === 'admin')

	const {
		data: { allClients = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_CLIENTS)

	const [
		importClients,
		{ data: { importClientsFromOdoo = null } = {}, loading: importLoading },
	] = useLazyQuery(IMPORT_CLIENTS)

	const handleSynchronizationClick = () => {
		importClients()
		if (importLoading) {
			return <Spinner />
		} else if (importClientsFromOdoo) {
			toast.success('Syncronisation réussie')
		}
	}

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
				<Button
					onClick={handleSynchronizationClick}
					className="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButtonBase-root  css-1knaqv7-MuiButtonBase-root-MuiButton-root"
				>
					<LoopIcon className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon" />{' '}
					Synchronisation
				</Button>
			</GridToolbarContainer>
		)
	}

	const columns = [
		{
			field: 'actions',
			headerName: 'Action',
			filterable: false,
			sortable: false,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<Link
						to={`/modifier-client/${thisRow.id}`}
						style={{ margin: '0 auto' }}
						className="listEditBtn"
					>
						<EditIcon className="editBtnIcon" /> Modifier
					</Link>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 150,
			hide: true,
			flex: 1,
			filterable: false,
			sortable: false,
		},
		{
			field: 'company',
			headerName: 'Société',
			minWidth: 200,
			flex: 1,

			renderCell: (params) => {
				const { row } = params
				return (
					<strong style={{ textTransform: 'capitalize', marginLeft: '20px' }}>
						{row.company}
					</strong>
				)
			},
		},
		{
			field: 'adminstrator',
			headerName: 'Adminstrateur',
			minWidth: 200,
			flex: 1,
		},
		{
			field: 'email',
			headerName: 'E-mail',
			minWidth: 200,
			flex: 1,
		},

		{
			field: 'language',
			headerName: 'Langue',
			minWidth: 100,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return row.language.split('_')[0].toUpperCase()
			},
		},
		{ field: 'phone', headerName: 'Téléphone', minWidth: 100, flex: 1 },
		{ field: 'tva', headerName: 'Numéro TVA', minWidth: 100, flex: 1 },
		{ field: 'odooRef', headerName: 'Référence', minWidth: 100, flex: 1 },
	]

	if (localStorage.getItem('clientUpdateMsg') !== '') {
		toast.success(localStorage.getItem('clientUpdateMsg'))
	}

	if (localStorage.getItem('clientCreateMsg') !== '') {
		toast.success(localStorage.getItem('clientCreateMsg'))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('clientUpdateMsg', '')
			localStorage.setItem('clientCreateMsg', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [allClients])

	useEffect(() => {
		updateName(
			JSON.stringify([
				{ level: '1', title: 'Liste des clients', url: '/liste-clients' },
			])
		)
	}, [updateName])

	if (loading || importLoading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-center" transition={Slide} />
			{allClients && (
				<DataGrid
					disableSelectionOnClick={true}
					localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
					rows={allClients}
					autoHeight={true}
					columns={
						isAdmin
							? columns
							: columns.filter(
									(col) => col.field !== 'email' && col.field !== 'phone'
							  )
					}
					components={{
						Toolbar: CustomToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}
		</>
	)
}
