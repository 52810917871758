import React, { useEffect, useContext } from 'react'
import { useCurrentUser } from '../../currentUserContext'
import { Redirect } from 'react-router-dom'
import useStyles from './useStyles'
import Logo from '../../../asset/logoBg.png'

import { Context } from '../../breadcrumsContext'
import { useDocumentTitle } from '../../documentTitle'

const Home = () => {
	const { updateName } = useContext(Context)
	useDocumentTitle('Accueil')
	const currentUser = useCurrentUser()

	const { toolbar, content } = useStyles()

	useEffect(() => {
		updateName(JSON.stringify([]))
	}, [updateName])

	if (!currentUser) return <Redirect to="/" />

	return (
		// <div className={root}>
		<main className={content}>
			<div className={toolbar}></div>
			<img
				src={Logo}
				alt="Paycore"
				style={{ width: '200px', marginTop: '80px' }}
			/>
		</main>
		// </div>
	)
}

export default Home
