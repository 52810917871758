import React, { useEffect, useContext } from 'react'
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	frFR,
} from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery, useLazyQuery } from '@apollo/client'
import Spinner from '../../../UI/Spinner'
import Stack from '@mui/material/Stack'
import * as moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import LoopIcon from '@mui/icons-material/Loop'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

import EditIcon from '@material-ui/icons/BorderColor'

const ALL_SERVICES = loader('./graphql/allServices.graphql')
const IMPORT_SERVICES = loader('./graphql/importServicesFromOdoo.graphql')

export default function ServiceList() {
	const { updateName } = useContext(Context)
	useDocumentTitle('Liste des services')

	const {
		data: { allServices = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_SERVICES)

	const [
		importServices,
		{ data: { importServicesFromOdoo = null } = {}, loading: importLoading },
	] = useLazyQuery(IMPORT_SERVICES)

	const handleSynchronizationClick = () => {
		importServices()
	}

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
				<Button
					onClick={handleSynchronizationClick}
					className="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButtonBase-root  css-1knaqv7-MuiButtonBase-root-MuiButton-root"
				>
					<LoopIcon className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon" />{' '}
					Synchronisation
				</Button>
			</GridToolbarContainer>
		)
	}

	const columns = [
		{
			field: 'actions',
			headerName: 'Action',
			filterable: false,
			sortable: false,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<Link
						to={`/modifier-service/${thisRow.id}`}
						style={{ margin: '0 auto' }}
						className="listEditBtn"
					>
						<EditIcon className="editBtnIcon" /> Modifier
					</Link>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 150,
			hide: true,
			flex: 1,
			filterable: false,
			sortable: false,
		},
		{ field: 'name', headerName: 'Service', minWidth: 150, flex: 1 },
		{ field: 'odooRef', headerName: 'Reference', minWidth: 150, flex: 1 },
		{ field: 'description', headerName: 'Description', minWidth: 200, flex: 1 },
		{ field: 'price', headerName: 'Prix', minWidth: 200, flex: 1 },
		{
			field: 'createdBy',
			headerName: 'Créé par',
			minWidth: 90,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return (
					<>
						<Stack
							direction="row"
							spacing={1}
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<Tooltip title={row?.createdBy?.userName?.toUpperCase()}>
								<Avatar sx={{ bgcolor: row?.createdBy?.color }}>
									{row?.createdBy?.userName !== ''
										? row?.createdBy?.userName?.substring(0, 2).toUpperCase()
										: 'AA'}
								</Avatar>
							</Tooltip>
						</Stack>
					</>
				)
			},
			valueGetter: ({ value }) => value?.userName,
		},

		{
			field: 'createdAt',
			headerName: 'Date de création',
			minWidth: 150,
			flex: 1,
			valueGetter: ({ value }) => {
				return moment(value, 'x').format('DD/MM/YY HH:mm').toString()
			},
		},
		{
			field: 'updatedBy',
			headerName: 'Modifié par',
			minWidth: 100,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return (
					<>
						{row.updatedBy && (
							<Stack
								direction="row"
								spacing={1}
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Tooltip title={row?.updatedBy?.userName?.toUpperCase()}>
									<Avatar sx={{ bgcolor: row?.updatedBy?.color }}>
										{row.updatedBy !== ''
											? row.updatedBy?.userName?.substring(0, 2).toUpperCase()
											: 'AA'}
									</Avatar>
								</Tooltip>
							</Stack>
						)}
					</>
				)
			},
			valueGetter: ({ value }) => value?.userName,
		},
		{
			field: 'updatedAt',
			headerName: 'Dérniére modification',
			minWidth: 150,
			flex: 1,
			valueGetter: ({ value }) => {
				return moment(value, 'x').format('DD/MM/YY HH:mm').toString()
			},
		},
	]

	if (localStorage.getItem('serviceCreateMsg') !== '') {
		toast.success(localStorage.getItem('serviceCreateMsg'))
	}

	if (localStorage.getItem('serviceUpdateMsg') !== '') {
		toast.success(localStorage.getItem('serviceUpdateMsg'))
	}
	if (importServicesFromOdoo) {
		toast.success('Syncronisation réussie')
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('serviceCreateMsg', '')
			localStorage.setItem('serviceUpdateMsg', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [allServices])

	useEffect(() => {
		updateName(
			JSON.stringify([
				{ level: '1', title: 'Liste des services', url: '/liste-services' },
			])
		)
	}, [updateName])

	if (loading || importLoading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-center" transition={Slide} />
			{allServices && (
				<DataGrid
					disableSelectionOnClick={true}
					localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
					rows={allServices}
					autoHeight={true}
					columns={columns}
					components={{
						Toolbar: CustomToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}
		</>
	)
}
