import React, { useContext, useEffect } from 'react'
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import Spinner from '../../../UI/Spinner'
import Stack from '@mui/material/Stack'
import * as moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import '../../../../asset/style.css'

import EditIcon from '@material-ui/icons/BorderColor'

const ALL_CLIENTS = loader('./graphql/allClients.graphql')

export default function ClientRateList() {
	const { updateName } = useContext(Context)
	useDocumentTitle('Liste des tarifs par client')
	const { data: { allClients = null } = {}, loading } = useQuery(ALL_CLIENTS)

	const columns = [
		{
			field: 'actions',
			headerName: 'Action',
			filterable: false,
			sortable: false,
			minWidth: 120,
			flex: 1,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<Link
						to={`/client-tarifs/${thisRow.id}`}
						style={{ margin: '0 auto' }}
						className="listEditBtn"
					>
						<EditIcon className="editBtnIcon" /> Modifier
					</Link>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 150,
			hide: true,
			flex: 1,
			filterable: false,
			sortable: false,
		},
		{ field: 'company', headerName: 'Société', minWidth: 200, flex: 1 },
		{
			field: 'adminstrator',
			headerName: 'Adminstrateur',
			minWidth: 200,
			flex: 1,
		},
		{ field: 'email', headerName: 'E-mail', minWidth: 200, flex: 1 },
		{ field: 'tva', headerName: 'Numéro TVA', minWidth: 100, flex: 1 },
		{
			field: 'createdBy',
			headerName: 'Créé par',
			minWidth: 90,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return (
					<>
						<Stack
							direction="row"
							spacing={1}
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							{row?.createdBy && (
								<Tooltip title={row?.createdBy?.userName?.toUpperCase()}>
									<Avatar sx={{ bgcolor: row?.createdBy?.color }}>
										{row?.createdBy?.userName !== ''
											? row?.createdBy?.userName?.substring(0, 2).toUpperCase()
											: 'AA'}
									</Avatar>
								</Tooltip>
							)}
						</Stack>
					</>
				)
			},
			valueGetter: ({ value }) => value?.userName,
		},

		{
			field: 'createdAt',
			headerName: 'Date de création',
			minWidth: 150,
			flex: 1,
			valueGetter: ({ value }) => {
				return moment(value, 'x').format('DD/MM/YY HH:mm').toString()
			},
		},
		{
			field: 'updatedBy',
			headerName: 'Modifié par',
			minWidth: 100,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return (
					<>
						{row.updatedBy && (
							<Stack
								direction="row"
								spacing={1}
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Tooltip title={row?.updatedBy?.userName?.toUpperCase()}>
									<Avatar sx={{ bgcolor: row?.updatedBy?.color }}>
										{row.updatedBy !== ''
											? row.updatedBy?.userName?.substring(0, 2).toUpperCase()
											: 'AA'}
									</Avatar>
								</Tooltip>
							</Stack>
						)}
					</>
				)
			},
			valueGetter: ({ value }) => value?.userName,
		},
		{
			field: 'updatedAt',
			headerName: 'Dérniére modification',
			minWidth: 150,
			flex: 1,
			valueGetter: ({ value }) => {
				return moment(value, 'x').format('DD/MM/YY HH:mm').toString()
			},
		},
	]

	if (localStorage.getItem('contactState') !== '') {
		toast.success(localStorage.getItem('contactState'))
	}

	if (localStorage.getItem('CreateContactState') !== '') {
		toast.success(localStorage.getItem('CreateContactState'))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('contactState', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('CreateContactState', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		updateName(
			JSON.stringify([
				{
					level: '1',
					title: 'Liste des tarifs par client',
					url: '/liste-clients-tarifs',
				},
			])
		)
	}, [updateName])
	if (loading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-center" transition={Slide} />
			{/* {allClients && ( */}
			<DataGrid
				disableSelectionOnClick={true}
				localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
				rows={allClients}
				autoHeight={true}
				columns={columns}
				components={{
					Toolbar: GridToolbar,
					NoResultsOverlay: () => {
						return (
							<Stack
								style={{ marginTop: '600px !important' }}
								alignItems="center"
								justifyContent="center"
							>
								<FilterListOffIcon />
								<p>Filter returns no result</p>
							</Stack>
						)
					},
					NoRowsOverlay: () => {
						return (
							<Stack
								style={{ marginTop: '600px !important' }}
								alignItems="center"
								justifyContent="center"
							>
								<FilterListOffIcon />
								<p>No Rows</p>
							</Stack>
						)
					},
				}}
			/>
			{/* )} */}
		</>
	)
}
