import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import { Chart, registerables } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

import '../../UI/toastify/ReactToastify.min.css'

import { Context } from '../../breadcrumsContext'
import { useDocumentTitle } from '../../documentTitle'

import '../../../asset/style.css'

Chart.register(...registerables)

const state = {
	labels: ['Janvier', 'Feverier', 'Mars', 'Avril', 'Mai', 'Juin'],
	datasets: [
		{
			label: 'Tâches',
			backgroundColor: 'rgba(75,192,192,1)',
			borderColor: 'rgba(0,0,0,1)',
			borderWidth: 2,
			data: [65, 59, 80, 81, 56, 34],
		},
	],
}

const chart = new Chart({
	type: 'line',
	data: state,
})

export default function Repports() {
	const { updateName } = useContext(Context)
	useDocumentTitle('Repports')

	useEffect(() => {
		updateName(
			JSON.stringify([{ level: '1', title: 'Rapports', url: '/rapports' }])
		)
	}, [updateName])

	return (
		<>
			<Box className="repportContainer">
				<Paper className="repportWidget">
					<h3>Tâches par utilisateur</h3>
					<Bar
						data={state}
						options={{
							title: {
								display: true,
								text: 'Average Rainfall per month',
								fontSize: 20,
							},
							legend: {
								display: true,
								position: 'right',
							},
						}}
					/>
					<select>
						<option>1</option>
						<option>1</option>
						<option>1</option>
					</select>
				</Paper>
				<Paper className="repportWidget">
					<h3>Total des taches</h3>
					<h1>53</h1>
					<select>
						<option>Jour</option>
						<option>Semaine</option>
						<option>Mois</option>
					</select>
				</Paper>
				<Paper className="repportWidget">
					<h3>Montant total des services (par utilisateur)</h3>
					<Bar
						data={state}
						options={{
							title: {
								display: true,
								text: 'Average Rainfall per month',
								fontSize: 20,
							},
							legend: {
								display: true,
								position: 'right',
							},
						}}
					/>
					<select>
						<option>1</option>
						<option>1</option>
						<option>1</option>
					</select>
				</Paper>
				<Paper className="repportWidget">
					<h3>Total des Tâches par mois</h3>
					<Bar
						data={state}
						options={{
							title: {
								display: true,
								text: 'Average Rainfall per month',
								fontSize: 20,
							},
							legend: {
								display: true,
								position: 'right',
							},
						}}
					/>
				</Paper>
				<Paper className="repportWidget">
					<h3>Montant total des services</h3>
					<h1>3 455 €</h1>
					<select>
						<option>Jour</option>
						<option>Semaine</option>
						<option>Mois</option>
					</select>
				</Paper>
				<Paper className="repportWidget">
					<h3>Montant total des services</h3>
					<Bar
						data={state}
						options={{
							title: {
								display: true,
								text: 'Average Rainfall per month',
								fontSize: 20,
							},
							legend: {
								display: true,
								position: 'right',
							},
						}}
					/>
				</Paper>
			</Box>
		</>
	)
}
