import React, { useEffect, useContext, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useParams, Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import SaveIcon from '@material-ui/icons/Save'
import Button from '@material-ui/core/Button'
import SystemInfo from '../../../UI/SystemInfo'
import Spinner from '../../../UI/Spinner'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'
import { useCurrentUser } from '../../../currentUserContext'
import { loader } from 'graphql.macro'

const CLIENT = loader('./graphql/client.graphql')
const UPDATE_CLIENT = loader('./graphql/updateClient.graphql')

const EditClient = () => {
	const { updateName } = useContext(Context)
	const currentUser = useCurrentUser()
	const params = useParams()

	const isAdmin =
		currentUser && currentUser.roles.find(({ role }) => role === 'admin')

	const { data: { client = null } = {}, loading: clientLoading } = useQuery(
		CLIENT,
		{
			variables: { id: params.id },
		}
	)

	const [updateClient, { data, error, loading }] = useMutation(UPDATE_CLIENT)

	const [formValues, setFormValues] = useState({
		company: '',
		language: 'fr_BE',
		adminstrator: '',
		tva: '',
		phone: '',
		email: '',
		accountantPhone: '',
		accountantEmail: '',
	})

	const [fieldErrors, setFieldErrors] = useState({
		company: false,
		tva: false,
		phone: false,
		email: false,
		accountantPhone: false,
		accountantEmail: false,
	})

	const validateInput = (fieldName, value) => {
		if (fieldName === 'email') {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+/
			return emailRegex.test(value)
		}
		if (fieldName === 'phone') {
			const phoneRegex = /^(\+\d{1,4})?(\d{9})$/
			return phoneRegex.test(value)
		}
		if (fieldName === 'tva') {
			const tvaRegex = /^BE\d{10}$/
			return tvaRegex.test(value)
		}
		if (fieldName === 'accountantEmail') {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+/
			return !value || emailRegex.test(value)
		}
		if (fieldName === 'accountantPhone') {
			const phoneRegex = /^(\+\d{1,4})?(\d{9})$/
			return !value || phoneRegex.test(value)
		}
		return true
	}

	const checkFormValidity = () => {
		const updatedErrors = {}
		Object.keys(formValues).forEach((fieldName) => {
			if (fieldName !== 'adminstrator') {
				const isValid = validateInput(fieldName, formValues[fieldName])
				updatedErrors[fieldName] = !isValid
			}
		})
		setFieldErrors(updatedErrors)
	}

	const handleFieldChange = (fieldName, value) => {
		const updatedFormValues = { ...formValues }
		updatedFormValues[fieldName] = value
		setFormValues(updatedFormValues)
	}

	const handleTvaChange = (e) => {
		const tvaValue = e.target.value
		handleFieldChange('tva', tvaValue)
		const isValid = validateInput('tva', tvaValue)
		setFieldErrors({ ...fieldErrors, tva: !isValid })
	}

	const handleEmailChange = (e) => {
		const emailValue = e.target.value
		handleFieldChange('email', emailValue)
		const isValid = validateInput('email', emailValue)
		setFieldErrors({ ...fieldErrors, email: !isValid })
	}

	const handlePhoneChange = (e) => {
		const phoneValue = e.target.value
		handleFieldChange('phone', phoneValue)
		const isValid = validateInput('phone', phoneValue)
		console.log(`Phone value: ${phoneValue}, isValid: ${isValid}`)
		setFieldErrors({ ...fieldErrors, phone: !isValid })
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		// Validation logic for all fields
		const updatedErrors = {}
		Object.keys(formValues).forEach((fieldName) => {
			if (fieldName !== 'adminstrator') {
				const isValid = validateInput(fieldName, formValues[fieldName])
				updatedErrors[fieldName] = !isValid
			}
		})

		setFieldErrors(updatedErrors)

		// Check overall form validity
		const formIsValid = Object.values(updatedErrors).every((valid) => !valid)

		if (formIsValid) {
			// Handle form submission
			updateClient({
				variables: {
					input: {
						id: params.id,
						company: formValues.company,
						language: formValues.language,
						adminstrator: formValues.adminstrator,
						tva: formValues.tva,
						phone: formValues.phone,
						email: formValues.email,
						accountantPhone: formValues.accountantPhone,
						accountantEmail: formValues.accountantEmail,
					},
				},
			})
		}
	}

	useDocumentTitle(
		client ? 'Modifier le client ' + client?.company : 'Modifier client '
	)

	useEffect(() => {
		if (client) {
			setFormValues({
				company: client.company || '',
				language: client.language || 'fr_BE',
				adminstrator: client.adminstrator || '',
				tva: client.tva || '',
				phone: client.phone || '',
				email: client.email || '',
				accountantPhone: client.accountantPhone || '',
				accountantEmail: client.accountantEmail || '',
			})

			checkFormValidity()
		}

		updateName(
			JSON.stringify([
				{
					level: '1',
					title: 'Modifier client',
					url: `/modifier-client/${client && client?.id}`,
				},
				{
					level: '2',
					title: `${client && client?.company}`,
					url: `/modifier-client/${client && client?.id}`,
				},
			])
		)
	}, [updateName, client])

	if (clientLoading || loading) return <Spinner />

	if (data) {
		localStorage.setItem(
			'clientUpdateMsg',
			'✔️ Le client a été modifié avec succès ! '
		)

		return <Redirect to="/liste-clients" />
	}

	return (
		<>
			{client && (
				<Box
					className="mainZone"
					component="form"
					noValidate
					autoComplete="off"
					onSubmit={handleSubmit}
				>
					<FormGroup className="formGrp-around" row>
						<TextField
							value={formValues.company}
							label="Société"
							name="company"
							size="small"
							margin="normal"
							required
							error={fieldErrors.company}
							onChange={(e) => {
								const updatedFormValues = { ...formValues }
								updatedFormValues.company = e.target.value
								setFormValues(updatedFormValues)
								checkFormValidity()
							}}
						/>
						<FormControl className="formSelect" size="small" margin="normal">
							<InputLabel>Langue</InputLabel>
							<Select
								labelId="Language"
								name="language"
								value={formValues.language}
								onChange={(e) => {
									const updatedFormValues = { ...formValues }
									updatedFormValues.language = e.target.value
									setFormValues(updatedFormValues)
									checkFormValidity()
								}}
							>
								<MenuItem key="fr" value="fr_BE">
									FR
								</MenuItem>
								<MenuItem key="nl" value="nl_BE">
									NL
								</MenuItem>
								<MenuItem key="en" value="en_BE">
									EN
								</MenuItem>
							</Select>
						</FormControl>
					</FormGroup>
					<FormGroup className="formGrp-around" row>
						<TextField
							value={formValues.adminstrator}
							label="Administrateur"
							name="adminstrator"
							size="small"
							margin="normal"
							error={fieldErrors.adminstrator}
							onChange={(e) => {
								const updatedFormValues = { ...formValues }
								updatedFormValues.adminstrator = e.target.value
								setFormValues(updatedFormValues)
								checkFormValidity()
							}}
						/>
						<TextField
							value={formValues.tva}
							label="Numéro TVA"
							name="tva"
							size="small"
							margin="normal"
							required
							error={fieldErrors.tva}
							helperText={fieldErrors.tva ? 'Invalid TVA' : ''}
							onChange={handleTvaChange}
						/>
					</FormGroup>
					<FormGroup className="formGrp-around" row>
						<TextField
							value={formValues.phone}
							label="Téléphone"
							name="phone"
							type="tel"
							size="small"
							margin="normal"
							required
							error={fieldErrors.phone}
							onChange={handlePhoneChange}
						/>
						{isAdmin ? (
							<TextField
								value={formValues.email}
								label="E-mail"
								name="email"
								type="email"
								size="small"
								margin="normal"
								required
								error={fieldErrors.email}
								onChange={handleEmailChange} // Add this line
							/>
						) : (
							''
						)}
					</FormGroup>
					<FormGroup className="formGrp-around" row>
						<TextField
							value={formValues.accountantPhone}
							label="Comptable - Téléphone"
							name="accountantPhone"
							type="tel"
							size="small"
							margin="normal"
							error={fieldErrors.accountantPhone}
							onChange={(e) => {
								const updatedFormValues = { ...formValues }
								updatedFormValues.accountantPhone = e.target.value
								setFormValues(updatedFormValues)
								checkFormValidity()
							}}
						/>
						<TextField
							value={formValues.accountantEmail}
							label="Comptable - Email"
							name="accountantEmail"
							type="email"
							size="small"
							margin="normal"
							error={fieldErrors.accountantEmail}
							onChange={(e) => {
								const updatedFormValues = { ...formValues }
								updatedFormValues.accountantEmail = e.target.value
								setFormValues(updatedFormValues)
								checkFormValidity()
							}}
						/>
					</FormGroup>
					<FormGroup className="formGrp-around" row>
						<Button
							className="submitBtn"
							type="submit"
							variant="contained"
							color="primary"
							disabled={Object.values(fieldErrors).some((valid) => valid)}
						>
							<SaveIcon />
							&nbsp;Enregistrer
						</Button>
					</FormGroup>
					<FormGroup className="formGrp-around" row>
						<SystemInfo
							createdBy={client?.createdBy}
							createdAt={client?.createdAt}
							updatedBy={client?.updatedBy}
							updatedAt={client?.updatedAt}
						/>
					</FormGroup>
				</Box>
			)}
		</>
	)
}

export default EditClient
