import React, { useEffect, useContext } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import InputAdornment from '@material-ui/core/InputAdornment'
import SaveIcon from '@material-ui/icons/Save'
import { Button } from '@material-ui/core'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'

import Spinner from '../../../UI/Spinner'
import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

const CREATE_FACTURE = loader('./graphql/createFacture.graphql')
const CLIENT = loader('./graphql/client.graphql')

const CreateInvoice = () => {
	const { updateName } = useContext(Context)
	useDocumentTitle('Nouvelle facture')
	const params = useParams()
	const [selectedElms, setSelectedElms] = React.useState([])

	const selectedServices =
		localStorage.getItem('selectedServices') !== ''
			? localStorage.getItem('selectedServices').split(',')
			: []

	const [createFacture, { data, error, loading }] = useMutation(CREATE_FACTURE)
	const { data: { client = null } = {}, loading: clientLoading } = useQuery(
		CLIENT,
		{
			variables: { id: params.id },
		}
	)

	const getTotal = () => {
		var tot = 0
		client.unpaidServices.map((elm) => {
			selectedServices.map((selected) => {
				if (elm?.id === selected) tot += elm?.finalPrice * elm?.quantity
			})
		})

		return Number(tot)
	}

	const getSelectedItemDetailes = () => {
		const servicesToBePaid = client?.unpaidServices.filter(({ id }) =>
			selectedServices.includes(id)
		)
		setSelectedElms(servicesToBePaid)
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		const { totalServices } = e.target
		return createFacture({
			variables: {
				input: {
					clientId: params.id,
					amount: Number(totalServices.value),
					taskServices: selectedElms.map(
						({ id, finalPrice, service, quantity, task }) => ({
							price: Number(finalPrice),
							id,
							serviceOdooRef: service.odooRef,
							quantity,
							taskId: task.id,
						})
					),
				},
			},
		})
	}

	useEffect(() => {
		if (client) {
			getSelectedItemDetailes()
		}
	}, [client])

	useEffect(() => {
		updateName(
			JSON.stringify([
				{
					level: '1',
					title: 'Nouvelle facture',
					url: `/nouvelle-facture/${client?.id}`,
				},
			])
		)
	}, [updateName, client])

	if (clientLoading || loading) return <Spinner />

	if (data) {
		localStorage.setItem(
			'invoiceCreateMsg',
			'✔️ La facture  a été crée avec  succès  ! '
		)
		return <Redirect to="/factures" />
	}

	return (
		<>
			{client && (
				<Box
					className="mainZone"
					component="form"
					noValidate
					autoComplete="off"
					onSubmit={handleSubmit}
				>
					<FormGroup className="formGrp" row>
						<TextField
							defaultValue={client && client.company}
							label="Client"
							name="reference"
							size="small"
							margin="normal"
							InputProps={{
								readOnly: true,
							}}
						/>
						<TextField
							label="Prix total"
							defaultValue={getTotal()}
							name="totalServices"
							type="number"
							size="small"
							margin="normal"
							InputProps={{
								endAdornment: <InputAdornment position="end">€</InputAdornment>,
								readOnly: true,
							}}
						/>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<Box sx={{ width: '100%' }}>
							<Paper sx={{ width: '100%', mb: 2 }}>
								<TableContainer component={Paper}>
									<Table sx={{ minWidth: 650 }} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell style={{ fontWeight: 'bold' }}>
													Service
												</TableCell>
												<TableCell style={{ fontWeight: 'bold' }} align="right">
													Quantité
												</TableCell>
												<TableCell style={{ fontWeight: 'bold' }} align="right">
													Prix&nbsp;(€)
												</TableCell>
												<TableCell style={{ fontWeight: 'bold' }} align="right">
													Total&nbsp;(€)
												</TableCell>
												<TableCell style={{ fontWeight: 'bold' }} align="right">
													Remarque
												</TableCell>
												<TableCell align="right"></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{client?.unpaidServices
												?.filter((firstArrayItem) => {
													return selectedServices.find((secondArrayItem) => {
														return firstArrayItem.id === secondArrayItem
													})
												})
												.map((row, i) => (
													<TableRow
														hover
														key={i}
														sx={{
															'&:last-child td, &:last-child th': { border: 0 },
														}}
													>
														<TableCell component="th" scope="row">
															{row?.service.name}
														</TableCell>
														<TableCell align="right">{row?.quantity}</TableCell>
														<TableCell align="right">
															{row?.finalPrice}
														</TableCell>
														<TableCell align="right">
															{row?.quantity * row?.finalPrice}
														</TableCell>
														<TableCell align="right">{row?.remark}</TableCell>
														<TableCell align="right">
															<Tooltip title="Retiré">
																<IconButton edge="end" aria-label="delete">
																	<DeleteIcon />
																</IconButton>
															</Tooltip>
														</TableCell>
													</TableRow>
												))}
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						</Box>
					</FormGroup>
					<FormGroup className="formGrp formSubmitBtn" row>
						<Button
							className="submitBtn"
							type="submit"
							variant="contained"
							color="primary"
						>
							{' '}
							<SaveIcon />
							&nbsp;Enregistrer{' '}
						</Button>
					</FormGroup>
				</Box>
			)}
		</>
	)
}

export default CreateInvoice
