import React, { useEffect, useContext } from 'react'
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import Spinner from '../../../UI/Spinner'
import Stack from '@mui/material/Stack'
import Badge from '@mui/material/Badge'
import ViewListIcon from '@mui/icons-material/ViewList'
import Box from '@mui/material/Box'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../../UI/toastify/ReactToastify.min.css'
import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import '../../../../asset/style.css'

import EditIcon from '@material-ui/icons/BorderColor'

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: '800px',
	},
})

const ALL_FACTURES = loader('./graphql/allFactures.graphql')

export default function AccoutingList() {
	const { updateName } = useContext(Context)
	useDocumentTitle('Factures')
	const {
		data: { allFactures = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_FACTURES)

	const columns = [
		// {
		// 	field: 'actions',
		// 	headerName: 'Action',
		// 	sortable: false,
		// 	filtrable: false,
		// 	renderCell: (params) => {
		// 		const api = params.api
		// 		const thisRow = {}

		// 		api
		// 			.getAllColumns()
		// 			.filter((c) => c.field !== '__check__' && !!c)
		// 			.forEach(
		// 				(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
		// 			)

		// 		return (
		// 			<Link
		// 				to={`/edit-client/${thisRow.id}`}
		// 				style={{ margin: '0 auto' }}
		// 				className="listEditBtn"
		// 			>
		// 				<EditIcon className="editBtnIcon" /> Afficher
		// 			</Link>
		// 		)
		// 	},
		// },
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 150,
			hide: true,
			flex: 1,
			filterable: false,
			sortable: false,
		},
		{ field: 'number', headerName: 'No. Facture', minWidth: 150, flex: 1 },
		{
			field: 'client',
			headerName: 'Société',
			minWidth: 150,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return (
					<strong style={{ textTransform: 'capitalize', marginLeft: '20px' }}>
						{row.client.company}
					</strong>
				)
			},
			valueGetter: (params) => params.row?.client.company.toString(),
		},
		{
			field: 'paidServices',
			headerName: 'Services',
			minWidth: 90,
			align: 'center',
			renderCell: (params) => {
				const { row } = params
				return (
					<>
						<NoMaxWidthTooltip
							placement="right-start"
							style={{ cursor: 'help' }}
							title={
								<TableContainer component={Paper}>
									<Table size="small">
										<TableHead>
											<TableRow>
												<TableCell>Service</TableCell>
												<TableCell>Quantité</TableCell>
												<TableCell>Prix</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{row?.paidServices?.map((elm) => {
												return (
													<TableRow
														key={elm.service.id}
														sx={{
															'&:last-child td, &:last-child th': { border: 0 },
														}}
													>
														<TableCell component="th" scope="row">
															{elm.service?.name}
														</TableCell>
														<TableCell align="right">{elm.quantity}</TableCell>
														<TableCell align="right">{elm.paidPrice}</TableCell>
													</TableRow>
												)
											})}
										</TableBody>
									</Table>
								</TableContainer>
							}
						>
							<Box sx={{ position: 'relative', display: 'inline-flex' }}>
								<Badge badgeContent={row.length} color="primary">
									<ViewListIcon />
								</Badge>
							</Box>
						</NoMaxWidthTooltip>
					</>
				)
			},
		},
		{ field: 'amount', headerName: 'Total (€)', minWidth: 200, flex: 1 },
		{ field: 'status', headerName: 'Status', minWidth: 200, flex: 1 },
	]

	if (localStorage.getItem('invoiceCreateMsg') !== '') {
		toast.success(localStorage.getItem('invoiceCreateMsg'))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('invoiceCreateMsg', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [allFactures])

	useEffect(() => {
		localStorage.setItem(
			'breadcrumbs',
			JSON.stringify([
				{ level: '1', title: 'Liste des factures', url: '/factures' },
			])
		)
	}, [])

	useEffect(() => {
		updateName(
			JSON.stringify([
				{ level: '1', title: 'Liste des Factures', url: '/factures' },
			])
		)
	}, [updateName])

	if (loading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-center" transition={Slide} />
			{allFactures && (
				<DataGrid
					disableSelectionOnClick={true}
					localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
					rows={allFactures}
					autoHeight={true}
					columns={columns}
					components={{
						Toolbar: GridToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}
		</>
	)
}
